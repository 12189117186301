import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import useReports from "../../../Hooks/useReports";
import DatePicker from "react-date-picker";
import Loading from "../../../components/LoadingPage/Loading";
import moment from "moment";
import Table from "react-bootstrap/Table";

const CostHistoryReport =()=>{

       const [fromUpdatedDate, setFromUpdatedDate] = useState<string>(moment().subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ss'));
       const [toUpdatedDate, setToUpdatedDate] = useState<string>(moment().format('YYYY-MM-DDTHH:mm:ss'));
       const [barcode, setBarcode] = useState<string>("");
       const [reportData, setReportData] = useState<Array<any>>([]);
       const { 
        getCostHistoryAPI,
        getCostHistoryLoading, 
        costHistoryReportExportAPI,
        costHistoryReportExportLoading} = useReports();
   
       const onBarcodeChange = (e:React.ChangeEvent<HTMLInputElement >) => {
       setBarcode(e.target.value as string);
     };
      const onSelectDate = (date: any, type: string) => {
       const formattedDate = moment(date).format("YYYY-MM-DDTHH:mm:ss");
       const typeAction: { [key: string]: () => void } = {
           "FUD": () => setFromUpdatedDate(formattedDate),
           "TUD": () => setToUpdatedDate(formattedDate),
       };
       if (typeAction[type]) {
           typeAction[type]();
       }
   };
     
        const fetchCostHistoryList = async() => {
           await getCostHistoryAPI({
               "fromDate": fromUpdatedDate,
               "toDate": toUpdatedDate,
               "barcode": barcode
           }).then((res: any) => {
           if (res) {
               setReportData(res);
           }
           });
     };
       const handleViewReport =()=>{
           fetchCostHistoryList();
       }
   
       const clearAll = () => {
         setFromUpdatedDate(moment().subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ss'));
         setToUpdatedDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
         setBarcode("");
         handleViewReport();
       };
       const handleCostHistoryExport = async()=>{
           const response = await costHistoryReportExportAPI({
               "fromUpdatedDate": fromUpdatedDate,
               "toUpdatedDate": toUpdatedDate,
               "barcode": barcode
           });
           const url = URL.createObjectURL(response);
           const link = document.createElement("a");
           link.href = url;
           link.setAttribute("download", `${localStorage.getItem("profileClientId")?.toUpperCase() ?? ""}_Cost_History_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
           document.body.appendChild(link);
           link.click();
       }
   
       useEffect(()=>{
           fetchCostHistoryList();
       },[])
       return (
       <> 
       <div className="report-filter-wrapper" style={{ marginBottom: "20px" }}>
           <div className="report-filter-item-wrapper">
             {getCostHistoryLoading || costHistoryReportExportLoading   ?  <Loading /> : null}
             <div className="filter-item-box">
               <label className="form-label">From Date</label>
               <DatePicker
                 format="MM/dd/yyyy"
                 openCalendarOnFocus={false}
                 dayPlaceholder="dd"
                 monthPlaceholder="mm"
                 yearPlaceholder="yyyy"
                 className="form-control"
                 value={fromUpdatedDate}
                 onChange={(date: any) => {
                   onSelectDate(date, "FUD");
                 }}
                 clearIcon={null}
                 calendarIcon={<i className="bi bi-calendar"></i>}
               />
             </div>
             <div className="filter-item-box">
               <label className="form-label">To Date</label>
               <DatePicker
                 format="MM/dd/yyyy"
                 openCalendarOnFocus={false}
                 dayPlaceholder="dd"
                 monthPlaceholder="mm"
                 yearPlaceholder="yyyy"
                 className="form-control"
                 value={toUpdatedDate}
                 onChange={(date: any) => {
                   onSelectDate(date, "TUD");
                 }}
                 clearIcon={null}
                 calendarIcon={<i className="bi bi-calendar"></i>}
               />
             </div>
             <div className="filter-item-box">
               <label className="form-label">Barcode</label>
               <input
                 type="text"
                 className="form-control"
                 placeholder="Enter Lot"
                 value={barcode}
                 onChange={onBarcodeChange}
               />
             </div>
             <div className="filter-item-box clear-all-box">
               <Button
                 type="button"
                 variant="primary"
                 onClick={handleViewReport}
               >
                 View
               </Button>
               <button
                 type="button"
                 className="clear-filter-btn"
                 onClick={clearAll}
               >
                 Clear All
               </button>
             </div>
           </div>
           <div className="report-export-box">
             <button type="button" className="report-export-btn" onClick={handleCostHistoryExport}>
               <ReactSVG className="svg-box" wrapper="span" src={exporticon} />
               Export
             </button>
           </div>
       </div>
       <div className="listing-table-wrapper">
           { reportData && reportData.length > 0 ? (
               <Table responsive="md" hover={true}>
               <thead>
                   <tr>
                    <th>Edited Date</th>
                    <th>Edited By</th>
                    <th>Barcode</th>
                    <th>Name</th>
                   <th>Prev Cost($)</th>
                   <th>Current Cost($)</th>
                   <th>UOM</th>
                   </tr>
               </thead>
               <tbody>
                   {reportData?.map((itemData: any) => (
                   <tr key={`'${itemData?.barcode}'`}>
                        <td>{itemData.updatedDate ? moment(itemData.updatedDate).format('YYYY-MM-DD') : '-'}</td>
                        <td>{itemData.updatedBy || "-"}</td>
                        <td>{itemData.barcode || "-"}</td>
                       <td>{itemData.rxName || "-"}</td>
                       <td>{itemData.prevCost || "-"}</td>
                       <td>{itemData.currentCost || '-'}</td>
                       <td>{itemData.uom || "-"}</td>
                   </tr>
                   ))}
               </tbody>
               </Table>
           )
           :(  <h6>Data not found!</h6> )   
           }
       </div>
       </>
       );
   
}
export default CostHistoryReport;