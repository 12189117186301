import React from 'react';
import "./NotFound.scss";
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import NotFoundImg from '../../assets/images/404-not-found.svg';

const NotFound = () => {

    const navigate = useNavigate();

    return (
        <div className="not-found-wrapper">
            <div className="image-wrapper flex-center">
                <img src={NotFoundImg} alt="404 - Page Not Found" />
            </div>
            <div className="page-content-wrapper flex-center">
                <div className="page-content">
                    <h1>404</h1>
                    <h2>Page Not Found</h2>
                    <p>The page you are looking for doesn't exist or has been moved.</p>
                    <Button
                        className="btn btn-primary"
                        onClick={() => navigate('/login')}
                    >
                        Back to Home
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default NotFound;