import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "./../global/Sidebar/Sidebar";
import TopBar from "./../global/Topbar/Topbar";
import Breadcrumb from "../components/breadcrum/Breadcrum";
import { useEffect, useState } from "react";
import { checkTokenExpiration } from "../utils/commonUtils";
import { tokenExpTime } from "../redux/auth/authSlice";
import { useAppSelector } from "../redux/hooks";
import useAuth from "../Hooks/useAuth";
import { toast } from "react-toastify";
const PrivateRoute = () => {
  const tokenExpiryTime = useAppSelector(tokenExpTime);
  const navigate = useNavigate();
  const { logout, logoutLoading } = useAuth();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem("sidebarCollapsed") === "true",
  );
  const toggleCollapsed = () => {
    localStorage.setItem("sidebarCollapsed", (!isCollapsed).toString());
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const state = checkTokenExpiration(tokenExpiryTime, 30);
      if (state) {
        toast.dismiss();
        toast.success("Session expired! Please login again.");
        localStorage.clear();
        await logout();
        navigate("/login");
      }
    }, 5000); // Check every 5 seconds
    // Clear interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [navigate]);
  return isLoggedIn ? (
    <div className={`main-wrapper ${isCollapsed ? "sidebarOpen" : ""}`}>
      <TopBar isCollapsed={isCollapsed} toggleCollapsed={toggleCollapsed} />
      <div className="main" style={{ display: "flex", flex: 1 }}>
        <Sidebar isCollapsed={isCollapsed} />
        <div className="content-wrapper">
          <Breadcrumb />
          <Outlet />
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
