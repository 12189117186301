import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import printicon from "../../../../assets/images/print-icon.svg";
import editicon from "../../../../assets/images/edit-icon.svg";
import duplicateicon from "../../../../assets/images/duplicate-icon.svg";
import deleteicon from "../../../../assets/images/delete-icon.svg";
import {
  checkPermission,
  formatDate,
  toggleClass,
} from "../../../../utils/commonUtils";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { DeleteConfirmModel } from "../../Itemlisting/popUpModels/deleteConfModel";
import useInventory from "../../../../Hooks/useInventory";
import { selectCurrentUserId } from "../../../../redux/auth/authSlice";
import { useAppSelector } from "../../../../redux/hooks";
import { DropDownValues } from "../../../../utils/types";
import { Units } from "../../../../redux/inventory/inventorySlice";
import useUnits from "../../../../Hooks/useUnits";
import Loading from "../../../../components/LoadingPage/Loading";
import moment from "moment";
import {
  PERMISSION_IDENTIFIERS,
  PERMISSION_MODULES,
} from "../../../../Hooks/constants";
import PrintBarcodeLabelDropdown from "../../../../components/printBarcodeLabelDropdown/printBarcodeLabelDropdown";

export const BasicInfoTab = ({
  toggleBarcodeModal,
  selectedItem,
  toggleDuplicateModal,
  selectedLabel, 
  setSelectedLabel,
  settingsList
}: any) => {
  const currentUserId = useAppSelector(selectCurrentUserId);
  const childRef = useRef<{ printBarcode: (labelId: string,selectedItem:any) => void }>(null);
  const { deleteInventoryItemAPI } = useInventory();
  const [showModal, setShowModal] = useState(false);
  const [unitsList, setUnitsList] = useState<any[]>([]);
  const { getUnitsAPI, getUnitsLoading } = useUnits();
  const [getuseUnitFieldVal, setGetuseUnitFieldVal] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getUnitsAPI({ searchText: "", includeDeleted: false })
      .then((res: any) =>
        setUnitsList(
          res
            .map((option: any) => {
              return {
                value: option.id,
                label: option.unitName,
              };
            })
            .sort(
              (a: { sortOrder: number }, b: { sortOrder: number }) =>
                a.sortOrder - b.sortOrder,
            ),
        ),
      )
      .catch();
  }, []);
  const editUser = () => {
    navigate("/edit-inventory");
  };
  const deleteItem = async () => {
    await deleteInventoryItemAPI({
      id: selectedItem?.id,
      deletedBy: currentUserId,
    });
    onClose();
    navigate("/inventory");
  };
  const onClose = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const toggleDelModal = (item: any) => {
    toggleClass();
    setShowModal(!showModal);
  };
  const getLabels = (unitsList: DropDownValues[], unit: Units[]) => {
    let val = "";
    unit.forEach((unit: Units) => {
      const label = unitsList.find(
        (option: any) => option.value === unit.unitId,
      )?.label;
      if (label) {
        val = val ? val + "," + label : label;
      }
    });
    return val;
  };

  useEffect(() => {
    setGetuseUnitFieldVal(getLabels(unitsList, selectedItem.useUnits));
  }, [unitsList]);
  const getOPName = () => {
    if (selectedItem.orderingProductMaster) {
      return `${selectedItem.orderingProductMaster?.productNo}-${selectedItem.orderingProductMaster?.productDescription} (${selectedItem.orderingProductMaster?.unit?.unitCode ?? "-"})`;
    }
    return "-";
  };
  const handlePrintBarcode = ()=>{
   if (childRef.current && selectedLabel) {
      childRef.current?.printBarcode(selectedLabel?.value,selectedItem); // Pass the selected label and a barcode ID
    }
  }
  return (
    <div className="invt-overview-content-wrapper">
      {getUnitsLoading ? <Loading /> : null}
      <div className="invt-overview-section-title full-width">
        <h5>Basic Information</h5>
        <div className="inventory-actions-box-wrapper">
          <PrintBarcodeLabelDropdown
            setSelectedLabel={setSelectedLabel}
            selectedLabel={selectedLabel}
            ref={childRef} // Pass the ref to the child component
          />
          <div className="print-barcode-btn-box">
            <span className="print-barcode-btn" onClick={handlePrintBarcode}>
              <ReactSVG className="svg-box" wrapper="span" src={printicon} />
              Print Barcode
            </span>
          </div>
          <div className="inventory-actions-box">
            {checkPermission(
              PERMISSION_MODULES.INVENTORY,
              PERMISSION_IDENTIFIERS.EDIT_INVENTORY_ITEM,
            ) && (
              <span
                className="invt-action-btn envt-edit-btn"
                onClick={editUser}
              >
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Edit</Tooltip>}
                  trigger={["hover", "focus"]}
                >
                  <ReactSVG className="svg-box" wrapper="span" src={editicon} />
                </OverlayTrigger>
              </span>
            )}

            {checkPermission(
              PERMISSION_MODULES.INVENTORY,
              PERMISSION_IDENTIFIERS.DUPLICATE_INVENTORY_ITEM,
            ) && (
              <span
                className="invt-action-btn envt-duplicate-btn"
                onClick={toggleDuplicateModal}
              >
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Duplicate</Tooltip>}
                  trigger={["hover", "focus"]}
                >
                  <ReactSVG
                    className="svg-box"
                    wrapper="span"
                    src={duplicateicon}
                  />
                </OverlayTrigger>
              </span>
            )}
            {checkPermission(
              PERMISSION_MODULES.INVENTORY,
              PERMISSION_IDENTIFIERS.DELETE_INVENTORY_ITEM,
            ) && (
              <span
                className="invt-action-btn envt-delete-btn"
                onClick={toggleDelModal}
              >
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Delete</Tooltip>}
                  trigger={["hover", "focus"]}
                >
                  <ReactSVG
                    className="svg-box"
                    wrapper="span"
                    src={deleteicon}
                  />
                </OverlayTrigger>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.ItemName ? settingsList.ItemLabels?.ItemName :"Item Name"}</label>
        <p>{selectedItem.rxName}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.SrxBarcode ? settingsList.ItemLabels?.SrxBarcode :"SRX Barcode"}</label>
        <p>{selectedItem.rxSRXId}</p>
      </div>

      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.ItemGroup ? settingsList.ItemLabels?.ItemGroup :"Item Group"}</label>
        <p>{selectedItem.drugName}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.Manufacturer ? settingsList.ItemLabels?.Manufacturer :"Manufacturer"}</label>
        <p>{selectedItem.manufacturer}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.OrderingProduct ? settingsList.ItemLabels?.OrderingProduct :"Ordering Product"}</label>
        <p>{getOPName()}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.Ndc ? settingsList.ItemLabels?.Ndc :"NDC"}</label>
        <p>{selectedItem.ndcCode}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.Lot ? settingsList.ItemLabels?.Lot :"LOT"}</label>
        <p>{selectedItem.lotNo}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.ExpirationDate ? settingsList.ItemLabels?.ExpirationDate :"Expiration Date"}</label>
        <p>
          {selectedItem.isNeverExpire
            ? "NA"
            : moment(selectedItem.expiryDate).format("MM/DD/YYYY")}
        </p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.InventoryUnit ? settingsList.ItemLabels?.InventoryUnit :"Inventory Unit"}</label>
        <p>{selectedItem.unit.unitName}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.UseUnits ? settingsList.ItemLabels?.UseUnits :"Use Units"}</label>
        <p>{getuseUnitFieldVal}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.ItemType ? settingsList.ItemLabels?.ItemType :"Item Type"}</label>
        <p>{selectedItem.rxType.rxTypeName}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.Stock ? settingsList.ItemLabels?.Stock :"Stock"}</label>
        <p>{selectedItem.stock.stockName}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.Active ? settingsList.ItemLabels?.Active :"Active"}</label>
        <p>{selectedItem.isActive ? "Yes" : "No"}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.SerialTracked ? settingsList.ItemLabels?.SerialTracked :"Serial Tracked"}</label>
        <p>{selectedItem.isSerialTracked ? "Yes" : "No"}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.CvxModel ? settingsList.ItemLabels?.CvxModel :"CVX/Model"}</label>
        <p>{selectedItem.cvx}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.UnitCost ? settingsList.ItemLabels?.UnitCost :"Unit Cost ($)"}</label>
        <p>{selectedItem.cptCode}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.Recall ? settingsList.ItemLabels?.Recall :"Recall"}</label>
        <p>{selectedItem.isRecalled ? "Yes" : "No"}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.Kit ? settingsList.ItemLabels?.Kit :"Kit"}</label>
        <p>{selectedItem.isKit ? "Yes" : "No"}</p>
      </div>
      <div className="invt-overview-info-box full-width">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.Notes ? settingsList.ItemLabels?.Notes :"Notes"}</label>
        <p>{selectedItem.notes}</p>
      </div>
      <h5 className="invt-overview-section-title full-width">
        Other Information
      </h5>
      <div className="invt-overview-info-box">
        <label>Use By Date</label>
        <p>
          {selectedItem.expiryDate
            ? moment(selectedItem.expiryDate).format("MM/DD/YYYY")
            : "NA"}
        </p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.SerialNumber ? settingsList.ItemLabels?.SerialNumber :"Serial Number"}</label>
        <p>{selectedItem.serialNumber || "NA"}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>Epics</label>
        <p>{selectedItem.epics || "NA"}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.UserDef1 ? settingsList.ItemLabels?.UserDef1 :"User Def 1"}</label>
        <p>{selectedItem.userDef1 || "NA"}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.UserDef2 ? settingsList.ItemLabels?.UserDef2 :"User Def 2"}</label>
        <p>{selectedItem.userDef2 || "NA"}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.UserDef3 ? settingsList.ItemLabels?.UserDef3 :"User Def 3"}</label>
        <p>{selectedItem.userDef3 || "NA"}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.UserDef4 ? settingsList.ItemLabels?.UserDef4 :"User Def 4"}</label>
        <p>{selectedItem.userDef4 || "NA"}</p>
      </div>
      <div className="invt-overview-info-box">
        <label>{settingsList?.ItemLabels && settingsList?.ItemLabels?.UserDef5 ? settingsList.ItemLabels?.UserDef5 :"User Def 5"}</label>
        <p>{selectedItem.userDef5 || "NA"}</p>
      </div>
      <DeleteConfirmModel
        showModal={showModal}
        onClose={onClose}
        item={selectedItem}
        deleteItem={deleteItem}
      />
    </div>
  );
};
