import React from 'react';
import './common-header.scss';
import { Button } from 'react-bootstrap';

interface ICommonHeaderProps {
    pageTitle: string;
    pageSubtitle?: string;
    searchValue?: string;
    handleSearchChange?: (value: string) => void;
    actionButtons?: React.ReactNode;
}

const CommonHeader: React.FC<ICommonHeaderProps> = (props: ICommonHeaderProps) => {
    const { pageTitle, pageSubtitle, searchValue, handleSearchChange, actionButtons } = props;

    return (
        <div className="common-header-main">
            <div className="page-title-main">
                <h2>{pageTitle}</h2>
                {pageSubtitle && (<p>{pageSubtitle}</p>)}
            </div>
            <div className="search-bar-main">
                <div className='search-form'>
                    <input
                        type="text"
                        min={0}
                        className="form-control"
                        placeholder="Search"
                        onChange={(e: any) => handleSearchChange?.(e.target.value)}
                        value={searchValue ?? ""}
                    />
                    <Button
                        type="button"
                        variant="outline-secondary"
                        className="btn btn-secondary btn-clear-search"
                        onClick={() => handleSearchChange?.('')}
                    >
                        Clear
                    </Button>
                </div>
            </div>
            <div className='header-actions'>
                {actionButtons && (actionButtons)}
            </div>
        </div>
    )
}

export default CommonHeader;