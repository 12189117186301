import { Button, Form, Table } from "react-bootstrap";
import "../listingTitle/listingtitle.scss";
import "./commonHeaderWithImpoExpo.scss";
import { ReactSVG } from "react-svg";
import exporticon from "../../assets/images/export-icon.svg";
import importicon from "../../assets/images/import-icon.svg";
import uploadfileicon from "../../assets/images/upload-file-icon.svg";
import CustomModal from "../customModal/CustomModal";
import { toast } from "react-toastify";
const CommonHeaderWithImpoExpo = ({
  title,
  buttonTitle,
  href,
  disRecords,
  totalRecords,
  searchVal,
  setSearchVal,
  defaultFilter,
  isAddPermitted,
  exportList,
  importList,
  showImportFileModal,
  selectedFile,
  setSelectedFile,
  toggleImportXlsxModal,
  importErrorDetails
}: any) => {
  
  
  const selectFile = (event: any) => {
    const file = event.target.files[0];

    // Check if file is selected
    if (!file) {
      toast.error("No file selected");
      return;
    }

    // Check file type (must be .xlsx)
    const fileType = file.type;
    if (
      fileType !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      toast.error("Please upload a valid .xlsx file");
      return;
    }

    // Check file size (must be <= 5MB)
    const fileSizeMB = file.size / (1024 * 1024); // convert to MB
    if (fileSizeMB > 5) {
      toast.error("File size must not exceed 5MB");
      return;
    }
    setSelectedFile(file);
  };

  const onImportClick = async(formData:any) =>{
    importList(formData);
  }
  const handleFileImport = async () => {
    onImportClick(selectedFile);
  };
  const onExportClick = async() => {
    exportList();  
  };
  return (
    <div className="listing-title-box">
      <div className="listing-title-info">
        <h2>{title}</h2>
        <p>
          Displaying {disRecords} of {totalRecords} Results
        </p>
      </div>
      <div className="listing-searchbar-wrapper">
        <div className="listing-searchbar-item-wrapper">
          <div className="listing-searchbar-item-box">
            <input
              type="text"
              min={0}
              className="form-control"
              placeholder="Search"
              onChange={(e: any) => {
                setSearchVal({ ...searchVal, searchText: e.target.value });
              }}
              value={searchVal?.searchText ?? ""}
            />
          </div>
          <div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                checked={searchVal?.active ?? false}
                onChange={(e) => {
                  setSearchVal({
                    ...searchVal,
                    active: e.target.checked ? true : null,
                  });
                }}
              />
              <label className="form-check-label">Active</label>
            </div>
          </div>
          <Button
            type="button"
            variant="outline-secondary"
            className="btn-search"
            onClick={() => {
              setSearchVal({ ...defaultFilter });
            }}
          >
            Clear All
          </Button>
        </div>
      </div>
      <div className="listing-action-box">
        <div className="import-export-box-wrapper">
          <span
            className="import-export-btn import-btn"
            onClick={toggleImportXlsxModal}
          >
            <ReactSVG className="svg-box" wrapper="span" src={importicon} />{" "}
            Import
          </span>
          <span
            className="import-export-btn export-btn"
            onClick={onExportClick}
          >
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
            Export
          </span>
        </div>

        {isAddPermitted && (
          <>
            <div className="action-box">
              <Button variant="primary" href={href}>
                <i className="bi bi-plus"></i>
                {buttonTitle}
              </Button>
            </div>
            {/* <div className="filter-box">
              <ReactSVG className="svg-box" wrapper="span" src={filtericon} />
            </div> */}
          </>
        )}
      </div>
      {showImportFileModal && (
        <CustomModal
          id="upload-avatar-modal"
          title="Import"
          content={
            <>
              <div className="modal--content--wrapper">
                <div className="img-upload-box">
                  <Form.Control
                    type="file"
                    id="avatar-upload-input"
                    accept=".xlsx"
                    onChange={selectFile}
                  />
                  <ReactSVG
                    className="svg-box"
                    wrapper="span"
                    src={uploadfileicon}
                  />
                  <p>Drag and Drop your file here or Click to Upload</p>
                </div>
                <div className="file-type-hint">
                  <span>File Type: .xlsx</span>
                  <span>Maximum Size: 5 MB</span>
                </div>
                {selectedFile?.name && (
                  <div className="file-type-hint">
                    <span>File Name: {selectedFile?.name}</span>
                  </div>
                )}
              </div>
              {importErrorDetails && importErrorDetails.length > 0 && (
                <div className="import-error-section">
                  <h3>Error Details</h3>
                  <Table responsive="md" hover={true} className="error-tbl">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>UserName / Email</th>
                        <th>Error Message</th>
                      </tr>
                    </thead>
                    <tbody>
                       {importErrorDetails?.map((data:any)=>(
                          <tr>
                            <td>{data?.fName} {data?.lName}</td>
                            <td>{data?.userName ? data?.userName :data?.email}</td>
                            <td className="error-info">{data?.errorMessage}</td>
                          </tr>
                       ))}
                    </tbody>
                  </Table>
                  <p>Please correct this data in excel sheet & try again..!</p>
                </div>
              )
              }
              <div className="modal--action--btn--wrapper">
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={toggleImportXlsxModal}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="primary"
                  onClick={handleFileImport}
                  disabled={!selectedFile}
                >
                  Import
                </Button>
              </div>
            </>
          }
          onClose={toggleImportXlsxModal}
        />
      )}
    </div>
  );
};

export default CommonHeaderWithImpoExpo;
