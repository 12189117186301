import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./patientslisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import { ReactSVG } from "react-svg";
import { toggleClass } from "../../../utils/commonUtils";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import usePatient from "../../../Hooks/usePatient";
import Loading from "../../../components/LoadingPage/Loading";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import { Patient } from "../../../redux/patient/patientSlice";
import moment from "moment";
import CommonSearchOnly from "../../../components/commonSearchOnly/commonSearchOnly";
const PatientsListing = () => {
  const navigate = useNavigate();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { getPatientAPI, getPatientLoading } = usePatient();
  const { deletePatientAPI, deletePatientLoading } = usePatient();
  const [showModal, setShowModal] = useState(false);
  const [patientList, setPatientList] = useState<Patient[]>([]);
  const [currentPatient, setCurrentPatient] = useState<Patient>();
  const defaultFilter = { searchText: "" };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const deletePatient = async () => {
    await deletePatientAPI({
      id: currentPatient?.id
    });
    toggleModal();
    getPatient();
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };

  const getPatient = () => {
    getPatientAPI(searchFieldVal)
      .then((res: any) => setPatientList(res))
      .catch();
  };
  useEffect(() => {
    getPatient();
  }, [searchFieldVal]);
  return (
    <div className="patients-listing-wrapper">
      {getPatientLoading || deletePatientLoading ? <Loading /> : null}
      <CommonSearchOnly
        title="Patient Master"
        buttonTitle="Add New"
        href="/add-patient"
        disRecords={patientList.length || 0}
        totalRecords={patientList.length || 0}
        searchVal={searchFieldVal}
        setSearchVal={setSearchFieldVal}
        defaultFilter={defaultFilter}
      />
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>Patient ID</th>
              <th>First Name</th>
              <th>M.I.</th>
              <th>Last Name</th>
              <th>Gender</th>
              <th>DOB</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {patientList?.map((x: Patient) => (
              <tr
                key={x.id}
                onDoubleClick={() => navigate(`/view-patient/${x.id}`)}
              >
                <td title={x.patientCode}>{x.patientCode}</td>
                <td title={x.firstName}>{x.firstName}</td>
                <td>{x.mi}</td>
                <td title={x.lastName}>{x.lastName}</td>
                <td>{x.gender}</td>
                <td>{x.dob ? moment(x.dob).format("L") : x.dob}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href={`/view-patient/${x.id}`}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={viewicon}
                        />
                        View
                      </Dropdown.Item>
                      <Dropdown.Item href={`/edit-patient/${x.id}`}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentPatient(x);
                          toggleModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deletePatient}
        recordName={currentPatient?.patientCode || ""}
        modelName={`patient`}
      />
    </div>
  );
};

export default PatientsListing;
