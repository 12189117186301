import { getErrorMessage } from "../utils/commonUtils";
import {
  useRolesListMutation,
  usePermissionsListMutation,
  useCreateRolePermissionMutation,
  useCreateRoleMutation,
  useEditRoleMutation,
  useGetRolesPermissionMutation,
  useGetRolesByIdMutation,
  useDeleteRolesPermissionMutation,
  useDuplicateRolesPermissionMutation,
  useSetPermissionStatusActiveMutation,
  useSetPermissionStatusDeActiveMutation,
} from "../redux/rolesAndPermissions/rolesAndPermissionsApiSlice";
import { toast } from "react-toastify";

export default function useRolesAndPermissions() {
  const [getRoles, { isLoading: getRolesLoading }] = useRolesListMutation();

  const [getRolesPermission, { isLoading: getRolesPermissionLoading }] =
    useGetRolesPermissionMutation();

  const [getPermissionsList, { isLoading: getPermissionsLoading }] =
    usePermissionsListMutation();
  const [createRole, { isLoading: createRoleLoading }] =
    useCreateRoleMutation();
  const [createRolePermission, { isLoading: createPermissionLoading }] =
    useCreateRolePermissionMutation();

  const [editRole, { isLoading: editRoleLoading }] = useEditRoleMutation();
  const [getRolesById, { isLoading: getRolesByIdLoading }] =
    useGetRolesByIdMutation();
  const [deleteRolesPermission, { isLoading: deleteRolesPermissionLoading }] =
    useDeleteRolesPermissionMutation();
  const [duplicateRolesPermission,{isLoading:duplicateRolesPermissionLoading} ] = 
    useDuplicateRolesPermissionMutation();

  const [setPermissionStatusActive, {isLoading: setPermissionActiveLoading}] = useSetPermissionStatusActiveMutation();
  const [setPermissionStatusDeActive, {isLoading: setPermissionDeActiveLoading}] = useSetPermissionStatusDeActiveMutation();
  
  const getRolesAPI = async (data: any) => {
    try {
      const response: any = await getRoles(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const getRolesPermissionAPI = async (data: any) => {
    try {
      const response: any = await getRolesPermission(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const getPermissionsListAPI = async (data: any) => {
    try {
      const response: any = await getPermissionsList(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const createPermissionsAPI = async (data: any) => {
    try {
      const response: any = await createRolePermission(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const createRoleAPI = async (data: any) => {
    try {
      const response: any = await createRole(data).unwrap();
      toast.success("New Role added successfully");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const editRoleAPI = async (data: any) => {
    try {
      const response: any = await editRole(data).unwrap();
      toast.success("Data updated successfully");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getRolesByIdAPI = async (data: any) => {
    try {
      const response: any = await getRolesById(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const deleteRolesPermissionAPI = async (data: any) => {
    try {
      const response: any = await deleteRolesPermission(data).unwrap();
      toast.success("Success! Role has been Deleted.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const duplicateRolePermissionAPI = async (data: any) => {
      try {
        const response = await duplicateRolesPermission(data).unwrap();
        toast.success("Success! Role has been Duplicated.");
        return response.result;
      } catch (err: any) {
        const message = getErrorMessage(err);
        throw new Error(message);
      }
    };

  const setPermissionActiveAPI = async (data: any) => {
    try {
      const response = await setPermissionStatusActive(data).unwrap();
      toast.success("Success! Permission has been activated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  }

  const setPermissionInActiveAPI = async (data: any) => {
    try {
      const response = await setPermissionStatusDeActive(data).unwrap();
      toast.success("Success! Permission has been deactivated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  }

  return {
    getPermissionsListAPI,
    getRolesAPI,
    getRolesLoading,
    getPermissionsLoading,
    createPermissionsAPI,
    createRoleLoading,
    createRoleAPI,
    createPermissionLoading,
    editRoleLoading,
    editRoleAPI,
    getRolesPermissionAPI,
    getRolesPermissionLoading,
    getRolesByIdAPI,
    getRolesByIdLoading,
    deleteRolesPermissionAPI,
    deleteRolesPermissionLoading,
    duplicateRolePermissionAPI,
    duplicateRolesPermissionLoading,
    setPermissionActiveAPI,
    setPermissionActiveLoading,
    setPermissionInActiveAPI,
    setPermissionDeActiveLoading
  };
}
