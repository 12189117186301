import "./forgetPassword.scss";
import loginlogo from "../../assets/images/login-logo.svg";
import loginimage from "../../assets/images/login-image.jpg";
import passwordResetModalImage from "../../assets/images/pass-reset-succes-image.svg";
import Form from "react-bootstrap/Form";
import { Button, Modal, Image, Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import useAuth from "../../Hooks/useAuth";
import Loading from "../../components/LoadingPage/Loading";
const ForgetPassword = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const { forgotPasswordApi, forgotPasswordLoading } = useAuth();
  const currentUrl = new URL(window.location.href);
  const baseUrl = `${currentUrl.protocol}//${currentUrl.hostname}${currentUrl.port ? `:${currentUrl.port}` : ""}`;
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      baseURL: baseUrl,
      userEmailId: "",
      clientId: localStorage.getItem("clientId") ?? "",
    },
    validationSchema: Yup.object({
      userEmailId: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      clientId: Yup.string().required(),
    }),

    onSubmit: async (values: any) => {
      forgotPasswordApi(values).then((res) => {
        handleShow();
      });
    },
  });
  return (
    <div className="forgot-password-wrapper">
      {forgotPasswordLoading ? <Loading /> : null}
      <Container fluid>
        <Row style={{ minHeight: "100vh" }}>
          <Col xs={12} md={6}>
            <div className="login-content-wrapper forgot-pass-wrapper">
              <div className="login-content">
                <img src={loginlogo} alt="logo" />
                <h4>Forgot Password</h4>
                <p>To reset your password, Enter your e-mail address below.</p>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-4">
                    <Form.Label>Client ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Client ID"
                      id="clientId"
                      onChange={(e) => {
                        e.target.value = e.target.value.toUpperCase();
                        handleChange(e);
                      }}
                      value={values.clientId}
                      required
                      isInvalid={touched.clientId && !!errors.clientId}
                    />

                    {touched.clientId && errors.clientId ? (
                      <Form.Control.Feedback type="invalid">
                        Client ID is Required
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email Address"
                      id="userEmailId"
                      name="userEmailId"
                      onChange={handleChange}
                      value={values.userEmailId}
                      isInvalid={touched.userEmailId && !!errors.userEmailId}
                    />

                    {touched.userEmailId && errors.userEmailId ? (
                      <Form.Control.Feedback type="invalid">
                        Please provide valid email.
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-4 d-grid">
                    <Button type="submit" variant="primary">
                      Reset Password
                    </Button>
                  </Form.Group>
                  <Form.Group className="text-center">
                    <p>
                      Already have an account?{" "}
                      <Button color="primary-800" variant="link" href="/login">
                        Sign In
                      </Button>
                    </p>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </Col>
          <Col
            style={{
              backgroundImage: `url(${loginimage})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
            item
            xs={12}
            md={6}
          >
            <div className="login-image-wrapper">
              <h4>Company</h4>
              <h1>Overview</h1>
              <p>
                TriNet Medical provides Healthcare IT solutions with
                quantifiable results in the areas of Data Management services,
                Technology Services and Barcode Integration with EMR.
              </p>
              <p>
                We deliver highly valuable and innovative solutions that bridge
                our client's execution gaps through a clear understanding of
                their business needs and a culture of shared commitments.
              </p>
              <div className="url-link-box">
                <Link
                  className="text-primary-700"
                  to="https://www.trinetmedical.com/"
                  target="_blank"
                >
                  www.trinetmedical.com
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal id="password-link-success-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Image src={passwordResetModalImage} alt="img" />
          <h5>Success!</h5>
          <p>
            Reset password link sent successfully to <br />
            {values?.userEmailId ?? ""}
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ForgetPassword;
