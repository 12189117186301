import Form from "react-bootstrap/Form";
import "./adduser.scss";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useUser from "../../../Hooks/useUser";
import { NpiPopupModel } from "./PopUps/NpiPopupModel";
import Loading from "../../../components/LoadingPage/Loading";
import Select, { SingleValue } from "react-select";
import { useAppSelector } from "../../../redux/hooks";
import { selectedNpiData, selectedUser } from "../../../redux/user/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { toggleClass } from "../../../utils/commonUtils";
import { DropDownValues } from "../../../utils/types";
import React from "react";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
const passwordTipPopover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Password Combination</Popover.Header>
    <Popover.Body>
      1. Password is at least 8 characters long.
      <br />
      2. Password has at least 1 number.
      <br />
      3. Password has at least 1 upper case letter.
      <br />
      4. Password has at least 1 lower case letter.
      <br />
      5. Password has at least 1 special character.
    </Popover.Body>
  </Popover>
);
const AddUser = () => {
  const loginUserId = useAppSelector(selectCurrentUserId);
  const [showNpiSearchModal, setShowNpiSearchModal] = useState(false);
  const { createUsers, isLoading } = useUser();
  const { updateUsers, editUserLoading } = useUser();
  const { getUserCreateDDValues, getUserCreateDDValLoading } = useUser();
  const dynamicFormVal = useAppSelector(selectedNpiData);
  const navigate = useNavigate();
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const selectedUserData = useAppSelector(selectedUser);
  const [isFieldDisabled, setIsFieldDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);
  const initValidationSchema = {
    userName: Yup.string()
      .max(20, "User Name can not be more than 20 characters")
      .test('userName-required', 'User Name is required', function (value) {
        const { email } = this.parent;
        // Check if email is empty and userName value is missing
        if (!email && !value) {
          return this.createError({ message: 'User Name or Email is Required' });
        }
        return true; // Otherwise, validation passes
      })
      .notRequired(), // If email is filled, userName is not required
    password: Yup.string().required("Password is Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm Password is Required"),
    fName: Yup.string()
      .max(40, "First Name can not be more than 40 characters")
      .required("First Name is Required"),
    lName: Yup.string()
      .max(40, "Last Name can not be more than 40 characters")
      .required("Last Name is Required"),
    mi: Yup.string()
      .max(1, "Middle Initials can not be more than 1 character.")
      .notRequired(),
    title: Yup.string()
      .max(15, "Credentials can not be more than 15 characters")
      .required("Credentials are Required"),
    clinicalRoleId: Yup.string().required("Clinical Role is Required"),
    roleId: Yup.string().required("User Role is Required"),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid Email"
      )
      .max(100, "Email cannot be more than 100 characters")
      .test('email-required', 'Email is required', function (value) {
        const { userName } = this.parent;
        // Check if userName is empty and email value is missing
        if (!userName && !value) {
          return this.createError({ message: 'Email or User Name is required' });
        }
        return true; // Otherwise, validation passes
      })
      .notRequired(), // If userName is filled, email is not required
    isActive: Yup.boolean(),
    deaNumber: Yup.string().required("NPI is Required"),
    phone: Yup.string()
      .max(30, "Phone Number should not be more than 30 characters")
      .notRequired(),
  };
  const [defaultValidation, setDefaultValidation] =
    useState(initValidationSchema);

  const [dynamicUserVal, setDynamicUserVal] = useState({
    title: "",
    fName: "",
    lName: "",
    deaNumber: "",
    isActive: true,
    userName: "",
    password: "",
    mi: "",
    notes: "",
    email: "",
    clinicalRoleId: "",
    roleId: "",
    phone: "",
    confirmPassword: "",
  });
  const toggleNpiSearchModal = () => {
    toggleClass();
    setShowNpiSearchModal(!showNpiSearchModal);
  };

  const [clinicalRoleDD, setClinicalRoleDD] = useState<DropDownValues[]>();
  const [userRoleDD, setUserRoleDD] = useState<DropDownValues[]>();
  useEffect(() => {
    getUserCreateDDValues().then((res: any) => {
      if (res) {
        setClinicalRoleDD(
          res.clinicalRoles?.map((option: any) => {
            return {
              value: option.id,
              label: option.roleDescription,
            };
          }),
        );
        setUserRoleDD(
          res.roles?.map((option: any) => {
            return {
              value: option.id,
              label: option.roleName,
            };
          }),
        );
      } else {
        setUserRoleDD([]);
        setClinicalRoleDD([]);
      }
    });
  }, []);
  useEffect(() => {
    setDynamicUserVal({
      ...values,
      title: dynamicFormVal.title,
      fName: dynamicFormVal.firstName,
      lName: dynamicFormVal.lastName,
      deaNumber: dynamicFormVal.deaNumber,
    });
  }, [dynamicFormVal]);
  useEffect(() => {
    if (location.pathname === "/edit-user") {
      setIsEdit(true);
      if (selectedUserData?.id) {
        setDynamicUserVal({
          title: selectedUserData.title,
          fName: selectedUserData.fName,
          lName: selectedUserData.lName,
          deaNumber: selectedUserData.deaNumber,
          isActive: selectedUserData.isActive,
          userName: selectedUserData.userName,
          password: "",
          mi: selectedUserData.mi,
          notes: selectedUserData.notes,
          email: selectedUserData.email,
          clinicalRoleId: selectedUserData.clinicalRoleId,
          roleId: selectedUserData.roleId,
          phone: selectedUserData.phone,
          confirmPassword: "",
        });
      } else {
        navigate("/users-list");
      }
      setPasswordValidation();
    }
  }, [selectedUserData]);
  const setPasswordValidation = () => {
    setDefaultValidation((prevValues: any) => ({
      ...prevValues,
      password: Yup.string().notRequired() as Yup.StringSchema<string>,
      confirmPassword: Yup.string().notRequired() as Yup.StringSchema<string>,
    }));
  };
  const clinicalRoleChange = async (option: SingleValue<DropDownValues>) => {
    setDynamicUserVal({ ...values, clinicalRoleId: option?.value || "no" });
    if (option?.label.toLocaleLowerCase() === "provider") {
      setIsFieldDisabled(false);
      setDynamicUserVal({
        ...values,
        deaNumber: selectedUserData?.deaNumber ?? "",
      });
      setDefaultValidation((prevValues: any) => ({
        ...prevValues,
        deaNumber: Yup.string()
          .required("NPI is  Required")
          .matches(/^\d{10}$/, "NPI should be 10 digits for Provider & NA for Others"),
      }));
    } else {
      setIsFieldDisabled(true);
      setDefaultValidation({ ...initValidationSchema });
      setDynamicUserVal({ ...values, deaNumber: "NA" });
      if (isEdit) {
        setPasswordValidation();
      }
    }
  };
  const { handleChange, values, handleSubmit, touched, errors, validateForm } =
    useFormik({
      initialValues: {
        ...dynamicUserVal,
      },
      validationSchema: Yup.object({ ...defaultValidation }),

      onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
        validateForm();
        try {
          if (isEdit) {
            await updateUsers({ ...values, id: selectedUserData.id });
          } else {
            await createUsers(values);
          }
          navigate("/users");
        } catch (err) {}

        setSubmitting(false);
      },
      enableReinitialize: true,
    });
  useEffect(() => {
    const data = clinicalRoleDD?.find(
      (option: DropDownValues) => option.value === values.clinicalRoleId,
    );
    if (data?.label.toLocaleLowerCase() === "provider") {
      setIsFieldDisabled(false);
    }
  }, [values.clinicalRoleId, clinicalRoleDD]);
  return (
    <div className="add-user-wrapper">
      {getUserCreateDDValLoading || editUserLoading || isLoading ? (
        <Loading />
      ) : null}
      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} User</h2>
      </div>
      <div className="add-user-box">
        <Form onSubmit={handleSubmit}>
          {isEdit && (
            <Form.Group className="mb-4">
              <Form.Label>SPSRX ID</Form.Label>
              <Form.Control
                type="text"
                value={selectedUserData.spSRXID}
                disabled={true}
              />
            </Form.Group>
          )}
          <Form.Group className="mb-4">
            <Form.Label>
              User Name{values.email ? "" : <sup>*</sup>}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter User Name"
              id="userName"
              name="userName"
              onChange={(e) => handleChange("userName")(e.target.value ?? "")}
              value={values.userName}
              isInvalid={touched.userName && !!errors.userName}
            />
            {touched.userName && errors.userName ? (
              <Form.Control.Feedback type="invalid">
                {errors.userName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          {!isEdit && (
            <>
              <Form.Group className="mb-4 password-field-box">
                <Form.Label>
                  Password<sup>*</sup>
                </Form.Label>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  id="password"
                  name="password"
                  onChange={(e) =>
                    handleChange("password")(e.target.value ?? "")
                  }
                  value={values.password}
                  isInvalid={touched.password && !!errors.password}
                />
                {touched.password && errors.password ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                ) : null}

                {showPassword ? (
                  <i
                    className="bi bi-eye-fill"
                    onClick={() => {
                    setShowPassword(!showPassword);
                    }}
                  />
                  ) : (
                  <i
                    className="bi bi-eye-slash-fill"
                    onClick={() => {
                    setShowPassword(!showPassword);
                    }}
                  />
                )}
              </Form.Group>
               <Form.Group className="mb-4 password-field-box">
                <Form.Label>
                  Confirm Password<sup>*</sup>
                </Form.Label>
                <Form.Control
                  type={showVerifyPassword ? "text" : "password"}
                  placeholder="Enter Confirm Password"
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={(e) =>
                    handleChange("confirmPassword")(e.target.value ?? "")
                  }
                  value={values.confirmPassword}
                  isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                />
                {touched.confirmPassword && errors.confirmPassword ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                ) : null}

                {showVerifyPassword ? (
                  <i
                    className="bi bi-eye-fill"
                    onClick={() => {
                    setShowVerifyPassword(!showVerifyPassword);
                    }}
                  />
                  ) : (
                  <i
                    className="bi bi-eye-slash-fill"
                    onClick={() => {
                    setShowVerifyPassword(!showVerifyPassword);
                    }}
                  />
                )}
              </Form.Group>
            </>
          )}
          <Form.Group className="mb-4">
            <Form.Label>
              First Name<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              id="fName"
              name="fName"
              onChange={(e) => handleChange("fName")(e.target.value ?? "")}
              value={values.fName}
              isInvalid={touched.fName && !!errors.fName}
            />
            {touched.fName && errors.fName ? (
              <Form.Control.Feedback type="invalid">
                {errors.fName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Middle Initials</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Middle Initials"
              id="mi"
              name="mi"
              onChange={(e) => handleChange("mi")(e.target.value ?? "")}
              value={values.mi}
              isInvalid={touched.mi && !!errors.mi}
            />
            {touched.mi && errors.mi ? (
              <Form.Control.Feedback type="invalid">
                {errors.mi}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Last Name<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Last Name"
              id="lName"
              name="lName"
              onChange={(e) => handleChange("lName")(e.target.value ?? "")}
              value={values.lName}
              isInvalid={touched.lName && !!errors.lName}
            />
            {touched.lName && errors.lName ? (
              <Form.Control.Feedback type="invalid">
                {errors.lName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Credentials<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Credentials"
              id="title"
              name="title"
              onChange={(e) => handleChange("title")(e.target.value ?? "")}
              value={values.title}
              isInvalid={touched.title && !!errors.title}
            />
            {touched.title && errors.title ? (
              <Form.Control.Feedback type="invalid">
                {errors.title}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Clinical Role<sup>*</sup>
            </Form.Label>
            <Select
              classNamePrefix="react-select"
              options={clinicalRoleDD}
              id="clinicalRoleId"
              name="clinicalRoleId"
              value={clinicalRoleDD?.find(
                (option: DropDownValues) =>
                  option.value === values.clinicalRoleId,
              )}
              onChange={async (option) => {
                await clinicalRoleChange(option);
                handleChange("clinicalRoleId")(option?.value ?? "");
              }}
              className={`${
                touched.clinicalRoleId && errors.clinicalRoleId
                  ? "is-invalid-border"
                  : ""
              } react-select-container `}
            />
            {touched.clinicalRoleId && errors.clinicalRoleId && (
              <Form.Control.Feedback type="invalid">
                {errors.clinicalRoleId}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Access Role<sup>*</sup>
            </Form.Label>
            <Select
              classNamePrefix="react-select"
              options={userRoleDD}
              id="roleId"
              name="roleId"
              value={userRoleDD?.find(
                (option) => option.value === values.roleId,
              )}
              onChange={(option) => handleChange("roleId")(option?.value ?? "")}
              className={`${
                touched.roleId && !!errors.roleId ? "is-invalid-border" : ""
              } react-select-container `}
            />
            {touched.roleId && errors.roleId && (
              <Form.Control.Feedback type="invalid">
                {errors.roleId}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Email{values.userName ? "" : <sup>*</sup>}
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email Address"
              id="email"
              name="email"
              onChange={(e) => handleChange("email")(e.target.value)}
              value={values.email}
              isInvalid={touched.email && !!errors.email}
            />
            {touched.email && errors.email ? (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
         <Form.Group className="mb-4">
            <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Phone Number"
                id="phone"
                name="phone"
                onChange={(e) => handleChange("phone")(e.target.value)}
                value={values.phone}
                isInvalid={touched.phone && !!errors.phone}
              />
              {touched.phone && errors.phone ? (
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              ) : null}
          </Form.Group>
          <Form.Group className="mb-4 npi-field">
            <Form.Label>
              NPI<sup>*</sup>
            </Form.Label>
            <div className="input-with-button">
              <Form.Control
                type="text"
                placeholder="Enter NPI"
                id="deaNumber"
                name="deaNumber"
                onChange={handleChange}
                value={values.deaNumber}
                isInvalid={touched.deaNumber && !!errors.deaNumber}
                disabled={isFieldDisabled}
              />
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Open NPI Search</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <button
                  type="button"
                  onClick={toggleNpiSearchModal}
                  disabled={isFieldDisabled}
                >
                  Query
                </button>
              </OverlayTrigger>
            </div>
            {touched.deaNumber && errors.deaNumber && (
              <Form.Control.Feedback type="invalid">
                {errors.deaNumber}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {isEdit && (
            <>
              <Form.Group className="mb-4">
                <Form.Label>Provider Activity</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Provider Activity"
                  disabled={true}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>User Activity</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="User Activity"
                  disabled={true}
                />
              </Form.Group>
            </>
          )}

          <Form.Group className="mb-4">
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="Active"
                type="checkbox"
                id="isActive"
                name="isActive"
                onChange={handleChange}
                checked={values.isActive}
                disabled = {isEdit && loginUserId === selectedUserData.id}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-4 full-width">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="notes"
              name="notes"
              onChange={handleChange}
              value={values.notes}
            />
          </Form.Group>
          <Form.Group className="mb-4 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => {
                navigate("/users");
              }}
            >
              Cancel
            </Button>
            <Button type="submit">{isEdit ? "Update" : "Add"}</Button>
          </Form.Group>
        </Form>
      </div>

      {showNpiSearchModal && (
        <NpiPopupModel
          toggleNpiSearchModal={toggleNpiSearchModal}
          userData={{ fName: values.fName, lName: values.lName, deaNumber: values.deaNumber }}
        />
      )}
    </div>
  );
};

export default AddUser;
