export const defaultEmrConfigurations = {
    "WS URL": "https://srxtest.trinetmedical.com/getrxinfoJSON.asmx/GetRxIr",
    "Wrapper WS URL": "https://srxtest.trinetmedical.com/api/GetRxInfo",
    "ConfigStr": "TEST|2.4 XD|AllowWS",
    "Barcode": "TESTMED",
    "WkStationID": "TEST01",
    "Query": "TRUE",
    "Usage Reason": "",
    "Inventory": false,
    "Expiring Inventory": false,
    "Emr Order Id": "",
    "PatID": "TESTPT",
    "PatFName": "Test",
    "PatMName": "",
    "PatLName": "Patient",
    "PatDOB": "5/1/2000",
    "PatGender": "M",
    "UserID": "admin",
    "UserFName": "Admin",
    "UserMName": "A",
    "UserLName": "User",
    "UserDEA: LIC": 123456,
    "HCPID": "admin",
    "HCPFName": "Admin",
    "HCPMName": "A",
    "HCPLName": "User",
    "HCPDEA: LIC": 123456,
    "Server Date": "",
    "Server Time": "",
    "WkStationIP": "127.3.54.12",
    "Location: WHSE": "Warehouse",
    "Qty": 0.5,
    "Units": "ml",
    "UniqueID": "TESTPT-D1234-V1234"
}

export const defaultEmrValues = {
    wsUrl: '',
    accessKey: '',
    barcode: 'TEST',
    patID: 'TESTPT',
    patFName: 'Test',
    patMI: '',
    patLName: 'Patient',
    patDOB: '5/1/2000',
    patGender: 'M',
    userID: 'admin',
    userFName: 'Admin',
    userMI: '',
    userLName: 'User',
    userNum: 'LIC:123456',
    hcpid: 'admin',
    hcpfName: 'Admin',
    hcpmi: '',
    hcplName: 'User',
    hcpNum: 'LIC:123456',
    serverDate: '',
    serverTime: '',
    location: 'WHSE:Warehouse',
    qty: '0.5',
    units: 'ml',
    query: true,
    inventory: true,
    expiringInventory: true,
    emrOrderId: '',
    name: '',
    encounterId: '',
    encounterDate: '',
    emrOrderDate: '',
    reason: '',
    uniqueID: 'TESTPT-D1234-V1234'
}