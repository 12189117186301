import {
  Badge,
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Popover,
  Table,
  Tooltip,
} from "react-bootstrap";
import "./recordusage.scss";
import CustomModal from "../../components/customModal/CustomModal";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import DatePicker from "react-date-picker";
import { toggleClass } from "../../utils/commonUtils";
import useRecordUsage from "../../Hooks/useRecordUsage";
import useAdmin from "../../Hooks/useAdmin";
import Loading from "../../components/LoadingPage/Loading";
import Select from "react-select";
import { DropDownValues } from "../../utils/types";
import { toast } from "react-toastify";
import {
  Payload,
  Provider,
  RecordUsageRes,
} from "./../../redux/recordUsage/recordUsageApiSlice";
import { clientId } from "../../redux/auth/authSlice";
import { useAppSelector } from "../../redux/hooks";
import * as Yup from "yup";
import { useFormik } from "formik";
import _ from "lodash";
import usePatient from "../../Hooks/usePatient";
import moment from "moment";
interface RecordUsageState {
  locationItem: DropDownValues[];
  recordUsagePageSetting: any;
  isCurrentUserSet: boolean;
  patients: any[];
  ddPatients: DropDownValues[];
  ddProviders: DropDownValues[];
  ddLocation: DropDownValues[];
  providers: Provider[];
  itemData?: RecordUsageRes;
  shotData: any[];
}
const RecordUsage = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const inputRefs = {
    patient: useRef<HTMLInputElement>(null),
    provider: useRef<HTMLInputElement>(null),
    location: useRef<HTMLInputElement>(null),
    barcode: useRef<HTMLInputElement>(null),
    quantity: useRef<HTMLInputElement>(null),
    waste: useRef<HTMLInputElement>(null),
  };
  const {
    getDDvaluesAPI,
    getBarcodeDetailsAPI,
    getDDvaluesLoading,
    getBarcodeDetailsLoading,
  } = useRecordUsage();
  const { createPatientAPI, createPatientLoading } = usePatient();
  const { getTenantKeyAPI, getTenantKeyLoading } = useAdmin();
  const [isQuantityValid, setIsQuantityValid] = useState(false);
  const [isWasteValid, setIsWasteValid] = useState(false);
  const [patientReq, setPatientReq] = useState(false);
  const [locationReq, setLocationReq] = useState(false);
  const [locationId, setLocationId] = useState("");
  const [useUnitsList, setUseUnitsList] = useState<any>([]);
  const [providerReq, setProviderReq] = useState(false);
  const [barcodeReq, setBarcodeReq] = useState(false);
  const [barcodeErrorMsg, setBarcodeErrorMsg] = useState<string | null>(null);
  const logInUserName = localStorage.getItem("username") ?? "";
  const logInUserfName = localStorage.getItem("firstName") ?? "";
  const logInUserlName = localStorage.getItem("lastName") ?? "";
  const logInUserId = localStorage.getItem("id") ?? "";
  const [state, setState] = useState<RecordUsageState>({
    locationItem: [],
    recordUsagePageSetting: undefined,
    isCurrentUserSet: false,
    patients: [],
    ddPatients: [],
    ddProviders: [],
    ddLocation: [],
    providers: [],
    itemData: undefined,
    shotData: [],
  });

  const quantityPopover = (
    <Popover id="quantityPopover">
      <Popover.Body>
        <Table hover={true}>
          <thead>
            <tr>
              <th>QUANTITY</th>
              <th>UNIT</th>
              <th>LOCATION</th>
            </tr>
          </thead>
          <tbody>
            {state.itemData?.inventory?.map((item: any, index: number) => (
              <tr key={index}>
                <td>{item.quantity}</td>
                <td>{item.item.unit.unitName}</td>
                <td>{item.location.locationName}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Popover.Body>
    </Popover>
  );
  const [payload, setPayload] = useState<Payload>({
    accessKey: "",
    patID: "",
    patFName: "",
    patMI: "",
    patLName: "",
    patDOB: "",
    patGender: "",
    userID: localStorage.getItem("username") ?? "",
    userFName: logInUserfName,
    userMI: "",
    userLName: logInUserlName,
    userNum: "",
    hcpid: "",
    hcpfName: "",
    hcpmi: "",
    hcplName: "",
    hcpNum: "",
    serverDate: "",
    serverTime: "",
    location: "",
    barcode: "",
    qty: "0",
    units: "",
    query: "",
    uniqueId: "",
    inventory: true,
    expiringInventory: true,
    emrOrderId: "",
    name: "",
    encounterId: "",
    encounterDate: "",
    emrOrderDate: "",
    reason: "test",
    waste: "0",
    clientId: useAppSelector(clientId) ?? "",
  });
  const [showAddPatientModal, setShowAddPatientModal] = useState(false);
  const toggleShowAddPatientModal = () => {
    toggleClass();
    setShowAddPatientModal(!showAddPatientModal);
    resetForm();
  };
  useEffect(() => {
    getTenantKeyAPI({}).then((res: any) => {
      setPayload((prevPayload) => ({
        ...prevPayload,
        accessKey: res[0]?.settingValue ?? "",
      }));
    });

    getDDvaluesAPI({}).then((res) => {
      setState((prevState) => ({
        ...prevState,
        ddLocation:
          res.locations.map(
            (item: { locationName: any; locationCode: any }) => ({
              value: `${item.locationName} - ${item.locationCode}`,
              label: `${item.locationName} - ${item.locationCode}`,
            }),
          ) ?? [],

        locationItem:
          res.locations.map((item: any) => ({
            ...item,
            fullName: `${item.locationName} - ${item.locationCode}`,
          })) ?? [],
        patients:
          res.patients.map(
            (item: {
              patientCode: any;
              firstName: any;
              lastName: any;
              dob: string;
              mi: string;
            }) => ({
              ...item,
              fullName: `${item.lastName}, ${item.firstName} ${item.mi ?? ""} (${item.patientCode} ${item.dob ? moment(item.dob).format("MM/DD/YYYY") : ""})`,
            }),
          ) ?? [],
        ddPatients:
          res.patients.map(
            (item: {
              id: any;
              patientCode: any;
              firstName: any;
              lastName: any;
              dob: string;
              mi: string;
            }) => ({
              value: item.id,
              label: `${item.lastName}, ${item.firstName} ${item.mi ?? ""} (${item.patientCode} ${item.dob ? moment(item.dob).format("MM/DD/YYYY") : ""})`,
            }),
          ) ?? [],
        providers:
          res.providers.map(
            (item: { userName: any; fName: any; lName: any; id: string }) => ({
              ...item,
              fullName: `${item.lName}, ${item.fName} (${item.userName})`,
            }),
          ) ?? [],
        ddProviders:
          res.providers.map(
            (item: { id: string; userName: any; fName: any; lName: any }) => ({
              value: item.id,
              label: `${item.lName}, ${item.fName} (${item.userName})`,
            }),
          ) ?? [],
        recordUsagePageSetting: res.recordUsagePageSetting,
      }));
    });
    localStorage.setItem("recordUsage", JSON.stringify({}));
  }, []);
  const handlePatientSelect = (option: any) => {
    setPayload((prevPayload: any) => ({
      ...prevPayload,
      patID: option.patientCode ?? "",
      patFName: option.firstName ?? "",
      patMI: option.mi ?? "",
      patLName: option.lastName ?? "",
      patDOB: option.dob ?? "",
      patGender: option.gender ?? "",
    }));
    setPatientReq(false);
  };

  const handleProviderSelect = (option: any) => {
    setPayload((prevPayload: any) => ({
      ...prevPayload,
      hcpid: option.userName ?? "",
      hcpfName: option.fName ?? "",
      hcpmi: option.mi ?? "",
      hcplName: option.lName ?? "",
      hcpNum: option.deaNumber ?? "",
    }));
    setProviderReq(false);
  };
  const handleLocationSelect = (option: any) => {
    setPayload((prevPayload: any) => ({
      ...prevPayload,
      location: `${option.locationCode}:${option.locationName}`,
    }));
    setLocationId(option.id);
  };
  const getLocationWiseData = () => {
    if (
      payload.location &&
      state?.itemData?.inventory &&
      state?.itemData.inventory.length > 0
    ) {
      const item = state.itemData.inventory.find(
        (inventory) => inventory.location.id === locationId,
      );
      return `(${item?.quantity || ""} ${item?.item?.unit?.unitName || ""} - ${item?.location?.locationName || ""})`;
    } else {
      return "Inventory";
    }
  };
  const handleClickOutside = useCallback(
    async (event: MouseEvent) => {
      if (
        formRef.current &&
        formRef.current.contains(event.target as Node) &&
        !Object.values(inputRefs).some((ref) =>
          ref.current?.contains(event.target as Node),
        ) &&
        !["INPUT", "SELECT", "BUTTON", "A", "UL", "LI"].includes(
          (event.target as HTMLElement).tagName,
        )
      ) {
        isGetFormValid();
      }
    },
    [inputRefs],
  );
  const isGetFormValid = () => {
    setProviderReq(false);
    setPatientReq(false);
    setBarcodeReq(!!!payload.barcode);
    setLocationReq(false);
    setIsQuantityValid(false);
    setIsWasteValid(false);
  };
  const isRecordFormValid = () => {
    setProviderReq(!!!payload.hcpid);
    setPatientReq(!!!payload.patID);
    setBarcodeReq(!!!payload.barcode);
    setLocationReq(!!!payload.location);
    setIsQuantityValid(!(parseFloat(payload.qty) > 0));
    setIsWasteValid(!(parseFloat(payload.waste) >= 0));
    return (
      !providerReq &&
      !patientReq &&
      !barcodeReq &&
      !locationReq &&
      parseFloat(payload.qty) > 0 &&
      !isWasteValid
    );
  };
  const shotInUpdate = async () => {
    const isValid = isRecordFormValid();
    if (isValid && payload.location) {
      getBarcodeDetailsAPI({ ...payload, query: "false" })
        .then((res) => {
          if (res.status === "F") {
            toast.error(res.msg);
            setBarcodeErrorMsg(res.msg);
          } else {
            setBarcodeErrorMsg(null);
            toast.success(res.msg);
            if (parseFloat(payload.waste) >= 0) {
              if (parseFloat(payload.waste) > 0) {
                getBarcodeDetailsAPI({
                  ...payload,
                  qty: payload.waste,
                  query: "WASTEINV",
                }).then(() => {});
              }
            }
            setState((prevState) => ({
              ...prevState,
              shotData: [
                ...prevState.shotData,
                {
                  ...res,
                  stock: state.itemData?.list2,
                  qty: payload.qty,
                  unit: payload.units,
                  barcode: payload.barcode,
                  waste: payload.waste,
                },
              ],
              // itemData: undefined,
            }));
            setPayload((prevPayload: any) => ({
              ...prevPayload,
              qty: "0",
              barcode: "",
              waste: "0",
            }));
          }
        })
        .catch();
    }
  };

  useEffect(() => {
    const handleClickOutsideListener: EventListener = (event: Event) => {
      handleClickOutside(event as unknown as MouseEvent);
    };
    document.addEventListener("mousedown", handleClickOutsideListener);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideListener);
    };
  }, [handleClickOutside]);

  const {
    handleChange,
    values,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      isActive: true,
      firstName: "",
      lastName: "",
      dob: "",
      gender: "Male",
      patientCode: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is Required"),
      lastName: Yup.string().required("Last Name is Required"),
      dob: Yup.date(),
      // .required("Date of Birth is Required"),
      patientCode: Yup.string().required("Patient ID is Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const patientRes = await createPatientAPI({...values,dob: values.dob ? values.dob : ""});
        if (patientRes?.id) {
          setState((prevState) => ({
            ...prevState,
            patients: [
              ...prevState.patients,
              {
                ...patientRes,
                fullName: `${patientRes.lastName}, ${patientRes.firstName} ${patientRes.mi ?? ""} (${patientRes.patientCode} ${patientRes.dob !==  "" ? moment(patientRes.dob).format("MM/DD/YYYY") : ""})`,
              },
            ],
          }));
          setState((prevState) => ({
            ...prevState,
            ddPatients: [
              ...prevState.ddPatients,
              {
                value: patientRes.id,
                label: `${patientRes.lastName}, ${patientRes.firstName} ${patientRes.mi ?? ""} (${patientRes.patientCode} ${patientRes.dob !==  "" ? moment(patientRes.dob).format("MM/DD/YYYY") : ""})`,
              },
            ],
          }));
          handlePatientSelect(patientRes);
          toggleShowAddPatientModal();
        }
      } catch (err) {
        console.error(err);
      }
      setSubmitting(false);
    },
  });

  const setCurrentUser = () => {
    if (!state.isCurrentUserSet) {
      setState((prevState) => ({
        ...prevState,
        providers: _.uniqBy(
          [
            ...prevState.providers,
            {
              id: logInUserId,
              fName: logInUserfName,
              lName: logInUserlName,
              fullName: logInUserName,
              userName: logInUserName,
              mi: "",
            },
          ],
          (e) => {
            return e.id;
          },
        ),
      }));
      setState((prevState) => ({
        ...prevState,
        ddProviders: _.uniqBy(
          [
            ...prevState.ddProviders,
            {
              label: logInUserName,
              value: logInUserName,
            },
          ],
          (e) => {
            return e.label && e.value;
          },
        ),
      }));

      setPayload((prevValues) => ({
        ...prevValues,
        hcpid: logInUserName,
        hcpfName: logInUserfName,
        hcpmi: "",
        hcplName: logInUserlName,
        hcpNum: "",
      }));
      handleProviderSelect({
        id: logInUserId,
        fName: logInUserfName,
        lName: logInUserlName,
        fullName: logInUserName,
        userName: logInUserName,
        mi: "",
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        providers: [...prevState.providers].filter(
          (provider) => provider.id !== logInUserId,
        ),
      }));

      setState((prevState) => ({
        ...prevState,
        ddProviders: [...prevState.ddProviders].filter(
          (provider) =>
            provider.value !== logInUserName &&
            provider.label !== logInUserName,
        ),
      }));
      setPayload((prevValues) => ({
        ...prevValues,
        hcpid: "",
        hcpfName: "",
        hcpmi: "",
        hcplName: "",
        hcpNum: "",
      }));
    }
    setState((prevState) => ({
      ...prevState,
      isCurrentUserSet: !state.isCurrentUserSet,
    }));
  };

  const formatResult = useCallback(
    (item: Provider) => {
      if (state.isCurrentUserSet) {
        return localStorage.getItem("username") ?? "";
      }
      return `${item.userName}, ${item.fName} ${item.lName} (${item.id})`;
    },
    [state.isCurrentUserSet],
  );

  const handleBarcodeBlur = async () => {
    if (payload.barcode) {
      const oldPayloadData = JSON.parse(
        localStorage.getItem("recordUsage") ?? "{}",
      );
      const valid = oldPayloadData.barcode === payload.barcode;
      if (!valid) {
        localStorage.setItem("recordUsage", JSON.stringify(payload));

        try {
          const res: RecordUsageRes = await getBarcodeDetailsAPI({
            ...payload,
            query: "true",
          });

          if (res.status === "F") {
            toast.error(res.msg);
            setBarcodeErrorMsg(res.msg);
            return;
          }
          setBarcodeErrorMsg(null);
          const newUseUnitsList = (() => {
            if (res.inventory && res.inventory.length > 0) {
              const inventory = res.inventory[0]; // Get the first inventory item

              if (!inventory?.item) return []; // If no item exists, return an empty array

              // Define the default unit
              const defaultUnit = {
                value: inventory.item.unit.unitCode,
                label: inventory.item.unit.unitName,
                isDefault: false,
              };

              // Update the payload with the default unit value
              setPayload((prevPayload: any) => ({
                ...prevPayload,
                units: defaultUnit.value,
              }));

              // Map useUnits to the format required
              const useUnits = inventory.item.useUnits.map((item) => ({
                value: item.unit?.unitCode,
                label: item.unit?.unitName,
                isDefault: item.isDefault,
              }));

              // Return an array with the default unit followed by useUnits
              return [defaultUnit, ...useUnits];
            }

            // Return an empty array if no inventory exists
            return [];
          })();

          setUseUnitsList(newUseUnitsList);

          // const defaultUnit = newUseUnitsList.find(
          //   (unit) => unit.isDefault,
          // // )?.value;
          // setPayload((prevPayload: any) => ({
          //   ...prevPayload,
          //   units: defaultUnit,
          // }));
          setState((prevState) => ({
            ...prevState,
            itemData: res,
          }));

          toast.success(res.msg);
        } catch {}
      }
    }
  }

  const resultStringKeyName = useMemo(() => {
    return state.isCurrentUserSet
      ? localStorage.getItem("username") ?? ""
      : "fullName";
  }, [state.isCurrentUserSet]);

  const clearData = () => {
    setPayload({ ...payload, barcode: "", qty: "0", waste: "0" });
  };

  return (
    <>
      <div className="page-title">
        {getDDvaluesLoading ||
        getBarcodeDetailsLoading ||
        getTenantKeyLoading ||
        createPatientLoading ? (
          <Loading />
        ) : null}
        <h2>Record Usage</h2>
      </div>
      <div className="record-usage-item-box">
        <Form ref={formRef}>
          <h5 className="form-section-title full-width">Basic Information</h5>
          <Form.Group className="mb-3" ref={inputRefs.patient}>
            <Form.Label>
              Patient ID*
              <Badge onClick={toggleShowAddPatientModal} bg="primary">
                Add Patient
              </Badge>
            </Form.Label>

            {state.recordUsagePageSetting?.isPatientControlFreeText ? (
              <ReactSearchAutocomplete
                placeholder="Enter Patient ID"
                showIcon={false}
                showClear={false}
                onSelect={handlePatientSelect}
                className={`${
                  patientReq ? "is-invalid-auto-search" : ""
                } autosearch `}
                fuseOptions={{
                  keys: ["patientCode", "firstName", "lastName"],
                }}
                resultStringKeyName="fullName"
                formatResult={(item: any) =>
                  `${item.lastName}, ${item.firstName} ${item.mi ?? ""} (${item.patientCode} ${item.dob ? moment(item.dob).format("MM/DD/YYYY") : ""})`
                }
                items={state.patients}
              />
            ) : (
              <Select
                classNamePrefix="react-select"
                options={state.ddPatients}
                value={state.ddPatients?.find(
                  (option: DropDownValues) => option.value === payload.patID,
                )}
                onChange={(option: any) =>
                  handlePatientSelect(
                    state.patients.find(
                      (item: any) => item.id === option.value,
                    ),
                  )
                }
                className={`${
                  patientReq ? "is-invalid-border" : ""
                } react-select-container `}
                placeholder="Enter Patient ID"
              />
            )}

            {patientReq && (
              <Form.Control.Feedback type="invalid">
                Patient is required
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Provider*</Form.Label>
            <div className="input-with-button" ref={inputRefs.provider}>
              {state.recordUsagePageSetting?.isProviderControlFreeText ? (
                <ReactSearchAutocomplete
                  placeholder="Enter Provider"
                  fuseOptions={{ keys: ["userName", "fName", "lName"] }}
                  showIcon={false}
                  showClear={false}
                  onSelect={handleProviderSelect}
                  // resultStringKeyName={
                  //   state.isCurrentUserSet ? logInUserName : "fullName"
                  // }
                  resultStringKeyName={resultStringKeyName}
                  // formatResult={(item: any) => {
                  //   if (state.isCurrentUserSet) {
                  //     return `${localStorage.getItem("username")}`;
                  //   }
                  //   return `${item.userName}, ${item.fName} ${item.lName} (${item.id})`;
                  // }}
                  className={`${
                    providerReq ? "is-invalid-auto-search" : ""
                  } autosearch `}
                  formatResult={formatResult}
                  items={state.providers}
                />
              ) : (
                <Select
                  classNamePrefix="react-select"
                  options={state.ddProviders}
                  value={state.ddProviders?.find(
                    (option: DropDownValues) => option.value === payload.userID,
                  )}
                  onChange={(option: any) =>
                    handleProviderSelect(
                      state.providers.find(
                        (item: any) => item.id === option.value,
                      ),
                    )
                  }
                  className={`${
                    providerReq ? "is-invalid-border" : ""
                  } react-select-container w-100`}
                  placeholder="Enter Provider"
                />
              )}
              {state.recordUsagePageSetting?.showCurrentUserButton && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Apply Current User</Tooltip>}
                  trigger={["hover", "focus"]}
                >
                  <button
                    type="button"
                    onClick={setCurrentUser}
                    style={
                      state.isCurrentUserSet
                        ? { backgroundColor: "#054d6e", color: "white" }
                        : {}
                    }
                  >
                    &lt; User
                  </button>
                </OverlayTrigger>
              )}
            </div>
            {providerReq && (
              <Form.Control.Feedback type="invalid">
                Provider is required
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3" ref={inputRefs.location}>
            <Form.Label>Location*</Form.Label>

            {state.recordUsagePageSetting?.isLocationControlFreeText ? (
              <ReactSearchAutocomplete
                placeholder="Enter Location"
                showIcon={false}
                showClear={false}
                fuseOptions={{ keys: ["locationName", "locationCode"] }}
                resultStringKeyName="fullName"
                onSelect={handleLocationSelect}
                formatResult={(item: any) =>
                  `${item.locationName} - ${item.locationCode}`
                }
                className={`${
                  locationReq ? "is-invalid-border" : ""
                } react-select-container w-100`}
                items={state.locationItem}
              />
            ) : (
              <Select
                classNamePrefix="react-select"
                options={state.ddLocation}
                value={state.ddLocation?.find(
                  (option: DropDownValues) => option.value === payload.location,
                )}
                onChange={(option: any) =>
                  handleLocationSelect(
                    state.locationItem.find(
                      (item: any) => item.fullName === option.value,
                    ),
                  )
                }
                className={`${
                  locationReq ? "is-invalid-border" : ""
                } react-select-container`}
                placeholder="Enter Location"
              />
            )}
            {locationReq && (
              <Form.Control.Feedback type="invalid">
                Location is required
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <h5 className="form-section-title full-width">
            Enter Item Usage Details
          </h5>
          <Form.Group className="mb-3">
            <Form.Label>Barcode*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Barcode"
              value={payload.barcode}
              onChange={(e) => {
                setBarcodeErrorMsg(null);
                setPayload((prevPayload: any) => ({
                  ...prevPayload,
                  barcode: e.target.value,
                }));
              }}
              ref={inputRefs.barcode}
              onBlur={handleBarcodeBlur}
              isInvalid={barcodeReq || !!barcodeErrorMsg}
            />
            {(barcodeReq || !!barcodeErrorMsg) && (
              <Form.Control.Feedback type="invalid">
                {!!barcodeErrorMsg ? barcodeErrorMsg : "Barcode is required"}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3" ref={inputRefs.quantity}>
            <Form.Label>Quantity*</Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                placeholder="Enter Quantity"
                onChange={(event) =>
                  setPayload((prevPayload: any) => ({
                    ...prevPayload,
                    qty: event.target.value,
                  }))
                }
                value={payload.qty}
                isInvalid={isQuantityValid}
              />
              {useUnitsList.length > 0 && (
                <Form.Select
                  aria-label="Select Quantity"
                  onChange={(event) =>
                    setPayload((prevPayload: any) => ({
                      ...prevPayload,
                      units: event.target.value,
                    }))
                  }
                >
                  {useUnitsList.map((unit: any) => (
                    <option
                      value={unit.value}
                      selected={payload.units === unit.value}
                    >
                      {unit.label}
                    </option>
                  ))}
                </Form.Select>
              )}

              {isQuantityValid ? (
                <Form.Control.Feedback type="invalid">
                  Quantity must be a positive number
                </Form.Control.Feedback>
              ) : null}
            </InputGroup>
            <OverlayTrigger
              trigger="hover"
              placement="bottom"
              overlay={quantityPopover}
            >
              <Form.Text id="quentity-hint" muted>
                {state.itemData && <span>{getLocationWiseData()}</span>}
              </Form.Text>
            </OverlayTrigger>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Waste</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Waste"
              onChange={(event) =>
                setPayload((prevValues) => ({
                  ...prevValues,
                  waste: event.target.value,
                }))
              }
              value={payload.waste}
              ref={inputRefs.waste}
              isInvalid={isWasteValid}
            />
            {isWasteValid ? (
              <Form.Control.Feedback type="invalid">
                Waste must be a positive number
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>

          <div className="record-item-info-wrapper full-width">
            {state.itemData && (
              <>
                <h5 className="form-section-title full-width">Item Details</h5>
                <div className="record-item-info-container">
                  <h5 className="form-section-title full-width">
                    {state.itemData?.rxName ?? "-"}
                  </h5>
                  <div className="record-item-info-box">
                    <label>Item Group</label>
                    <p>{state.itemData?.rxCategory ?? "-"}</p>
                  </div>
                  <div className="record-item-info-box">
                    <label>NDC11</label>
                    <p>{state.itemData?.ndC11 ?? "-"}</p>
                  </div>
                  <div className="record-item-info-box">
                    <label>Manufacturer</label>
                    <p>{state.itemData?.manufacturer ?? "-"}</p>
                  </div>
                  <div className="record-item-info-box">
                    <label>Serial Number</label>
                    <p>NA</p>
                  </div>
                  <div className="record-item-info-box">
                    <label>NDC/SKU</label>
                    <p>{state.itemData?.ndc ?? "-"}</p>
                  </div>
                  <div className="record-item-info-box">
                    <label>User Def. 2</label>
                    <p>
                      {state.itemData?.inventory
                        ? state.itemData.inventory[0]?.item?.userDef1
                        : "-"}
                    </p>
                  </div>
                  <div className="record-item-info-box">
                    <label>LOT Number</label>
                    <p>{state.itemData?.lotNo ?? "-"}</p>
                  </div>
                  <div className="record-item-info-box">
                    <label>Cost ($)</label>
                    <p>{state.itemData?.text3 ?? "-"}</p>
                  </div>
                  <div className="record-item-info-box">
                    <label>Expiration Date</label>
                    <p>{state.itemData?.expDate ?? "-"}</p>
                  </div>
                  <div className="record-item-info-box">
                    <label>Stock</label>
                    <p>{state.itemData?.list2 ?? "-"}</p>
                  </div>
                  <div className="record-item-info-box">
                    <label>CVX/Model</label>
                    <p>{state.itemData?.cvx ?? "-"}</p>
                  </div>
                  <div className="record-item-info-box">
                    <label>Item Type</label>
                    <p>{state.itemData?.list1 ?? "-"}</p>
                  </div>
                </div>
              </>
            )}

            {state.shotData.length > 0 && (
              <div className="record-item-info-table-wrapper">
                <Table bordered={false} hover={true}>
                  <thead>
                    <tr>
                      <th>BARCODE</th>
                      <th>ITEM NAME</th>
                      <th>NDC/SKU</th>
                      <th>LOT</th>
                      <th>EXP.DATE</th>
                      <th>STOCK</th>
                      <th>QUANTITY</th>
                      <th>UNIT</th>
                      <th>WASTE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.shotData.map((data: any, index: number) => (
                      <tr key={index}>
                        <td>{data.barcode ?? "-"}</td>
                        <td>{data.rxName ?? "-"}</td>
                        <td>{data.ndc ?? "-"}</td>
                        <td>{data.lotNo ?? "-"}</td>
                        <td>{data.expDate ?? "-"}</td>
                        <td>{data.stock ?? "-"}</td>
                        <td>{data.qty ?? "-"}</td>
                        <td>{data.unit ?? "-"}</td>
                        <td>{data.waste ?? "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>

          <Form.Group className="mb-3 mt-4 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={clearData}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                shotInUpdate();
              }}
            >
              Record
            </Button>
          </Form.Group>
        </Form>
      </div>

      {showAddPatientModal && (
        <CustomModal
          id="addpatientcmodal"
          title="Add Patient"
          content={
            <>
              <Form noValidate onSubmit={handleSubmit}>
                <div className="add-patient-box">
                  <Form.Group className="mb-2">
                    <Form.Label>
                      Patient ID<sup>*</sup>
                    </Form.Label>
                    <Form.Control
                      id="patientCode"
                      name="patientCode"
                      type="text"
                      placeholder="Enter Patient ID"
                      onChange={handleChange}
                      value={values.patientCode}
                      isInvalid={touched.patientCode && !!errors.patientCode}
                    />
                    {touched.patientCode && errors.patientCode ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.patientCode}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="mb-2">
                    <Form.Label>
                      First Name<sup>*</sup>
                    </Form.Label>
                    <Form.Control
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeholder="Enter First Name"
                      onChange={handleChange}
                      value={values.firstName}
                      isInvalid={touched.firstName && !!errors.firstName}
                    />
                    {touched.firstName && errors.firstName ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="mb-2">
                    <Form.Label>
                      Last Name<sup>*</sup>
                    </Form.Label>
                    <Form.Control
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeholder="Enter Last Name"
                      onChange={handleChange}
                      value={values.lastName}
                      isInvalid={touched.lastName && !!errors.lastName}
                    />
                    {touched.lastName && errors.lastName ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="mb-2">
                    <Form.Label>
                      DOB
                    </Form.Label>
                    <div className="calender-box">
                      <DatePicker
                        format="MM/dd/yyyy"
                        className={`${
                          touched.dob && !!errors.dob ? "is-invalid-border" : ""
                        } form-control`}
                        openCalendarOnFocus={false}
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                        showLeadingZeros={true}
                        value={values.dob}
                        clearIcon={null}
                        calendarIcon={<i className="bi bi-calendar"></i>}
                        onChange={(date) => setFieldValue("dob", date)}
                      />
                    </div>
                    {touched.dob && errors.dob ? (
                      <div className="invalid-feedback d-block">
                        {errors.dob}
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="mb-2">
                    <Form.Label>
                      Gender<sup>*</sup>
                    </Form.Label>
                    <div className="item-radio-content">
                      {["Male", "Female", "Other"].map((gender) => (
                        <Form.Check
                          key={gender}
                          inline
                          type="radio"
                          id={`gender-${gender}`}
                          name="gender"
                          label={gender}
                          value={gender}
                          onChange={handleChange}
                          checked={values?.gender === gender}
                        />
                      ))}
                      {touched.gender && errors.gender ? (
                        <div className="invalid-feedback d-block">
                          {errors.gender}
                        </div>
                      ) : null}
                    </div>
                  </Form.Group>

                  <div className="full-width  text-end">
                    <Button
                      style={{ marginRight: "10px" }}
                      variant="outline-primary"
                      onClick={toggleShowAddPatientModal}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" disabled={false}>
                      Add
                    </Button>
                  </div>
                </div>
              </Form>
            </>
          }
          onClose={toggleShowAddPatientModal}
        />
      )}
    </>
  );
};

export default RecordUsage;
