import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import leftArrowIcon from "../../assets/images/chevrons-left.svg";
import rightArrowIcon from "../../assets/images/chevrons-right.svg";
import { ReactSVG } from "react-svg";
import "./CommonPagination.scss";

interface ICommonPaginationProps {
    totalRecords: number;
    onChange: (page: number, perPage: number) => void;
}

const CommonPagination = ({ totalRecords, onChange }: ICommonPaginationProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const totalPages = Math.ceil(totalRecords / perPage);
    const maxVisiblePages = 5; // Show max 5 pages

    useEffect(() => {
        onChange(currentPage, perPage);
    }, [currentPage, perPage, onChange]);

    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handlePerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPerPage = Number(e.target.value);
        setPerPage(newPerPage);
        setCurrentPage(1);
    };

    /** Pagination Logic to Show Limited Pages */
    const getPageNumbers = () => {
        if (totalPages <= maxVisiblePages) {
            return Array.from({ length: totalPages }, (_, index) => index + 1);
        }

        let pages: (number | string)[] = [];
        const leftBound = Math.max(2, currentPage - 1);
        const rightBound = Math.min(totalPages - 1, currentPage + 1);

        pages.push(1); // Always show first page
        if (leftBound > 2) pages.push("..."); // Add "..." if there's a gap

        for (let i = leftBound; i <= rightBound; i++) {
            pages.push(i);
        }

        if (rightBound < totalPages - 1) pages.push("..."); // Add "..." if there's a gap
        pages.push(totalPages); // Always show last page

        return pages;
    };

    return (
        <div className="common-pagination-main mt-3 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
                <Form.Control
                    as="select"
                    value={perPage}
                    onChange={handlePerPageChange}
                    className="w-auto form-select pe-5"
                >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </Form.Control>
                <span className="ms-2">of {totalRecords} records</span>
            </div>

            <ul className="pagination mb-0">
                {/* Previous Button */}
                <li className={`page-item bg-transparent ${currentPage === 1 ? "disabled" : ""}`}>
                    <button
                        className="page-link shadow-none"
                        onClick={() => handlePageChange(currentPage - 1)}
                        aria-label="Previous"
                    >
                        <ReactSVG src={leftArrowIcon} className="svg-box" wrapper="span" />
                    </button>
                </li>

                {/* Page Numbers */}
                {getPageNumbers().map((page, index) => (
                    <li key={index} className={`page-item ${currentPage === page ? "active" : ""}`}>
                        {page === "..." ? (
                            <span className="page-link">...</span>
                        ) : (
                            <button className="page-link shadow-none" onClick={() => handlePageChange(Number(page))}>
                                {page}
                            </button>
                        )}
                    </li>
                ))}

                {/* Next Button */}
                <li className={`page-item bg-transparent ${currentPage === totalPages ? "disabled" : ""}`}>
                    <button
                        className="page-link shadow-none"
                        onClick={() => handlePageChange(currentPage + 1)}
                        aria-label="Next"
                    >
                        <ReactSVG src={rightArrowIcon} className="svg-box" wrapper="span" />
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default CommonPagination;