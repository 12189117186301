import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "../commonBaseApiSlice";

export const inventoryOrderApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    InvOrderList: builder.mutation({
      query: (data) => ({
        url: `/inventoryorder/getinventoryordersuggestions?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    InvOrderById: builder.mutation({
      query: (data) => ({
        url: `/inventoryorder/getbyid?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    InvOrders: builder.mutation({
      query: (data) => ({
        url: `/inventoryorder/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    invOrderDelete: builder.mutation({
      query: (data) => ({
        url: `/inventoryorder/delete?${getFilters(data)}`,
        method: "DELETE",
      }),
    }),
    invOrderMarkComplete: builder.mutation({
      query: (data) => ({
        url: `/inventoryorder/complete?${getFilters(data)}`,
        method: "DELETE",
      }),
    }),
    invOrderCreate: builder.mutation({
      query: (data) => ({
        url: `/inventoryorder/create`,
        method: "POST",
        body: data,
      }),
    }),
    orderStatusMaster: builder.mutation({
      query: () => ({
        url: `/orderstatusmaster/get`,
        method: "GET",
      }),
    }),
    getOrderItems: builder.mutation({
      query: (data) => ({
        url: `/inventoryorder/orderitems/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    getOrderItemsExport: builder.mutation({
      query: (data)=>({
        url: `inventoryorder/orderitems/export?${getFilters(data)}`,
        method: "POST",
      }),
    }),
  }),
});
export const {
  useInvOrderListMutation,
  useInvOrdersMutation,
  useInvOrderDeleteMutation,
  useInvOrderMarkCompleteMutation,
  useInvOrderCreateMutation,
  useOrderStatusMasterMutation,
  useInvOrderByIdMutation,
  useGetOrderItemsMutation,
  useGetOrderItemsExportMutation
} = inventoryOrderApiSlice;
