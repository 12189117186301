import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./userlisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import deactivateicon from "../../../assets/images/deactivate-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import duplicateicon from "../../../assets/images/duplicate-icon.svg";
import { ReactSVG } from "react-svg";
import useUser from "../../../Hooks/useUser";
import Loading from "../../../components/LoadingPage/Loading";
import { useNavigate } from "react-router-dom";
import { setSelectedUser } from "../../../redux/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { toggleClass } from "../../../utils/commonUtils";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import CommonHeaderWithImpoExpo from "../../../components/commonHeaderWithImpoExpo/commonHeaderWithImpoExpo";
import moment from "moment";
import DublicateUserModel from "../../../components/DublicateUserModel/DublicateUserModel";
import { toast } from "react-toastify";

const UserListing = () => {
  const loginUserId = useAppSelector(selectCurrentUserId);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getUserList, getUsersListLoading } = useUser();
  const { setUserActive, setUserStatusActiveLoading } = useUser();
  const { setUserInActive, setUserStatusDeActiveLoading } = useUser();
  const { deleteUserAPI, deleteUserLoading } = useUser();
  const {exportUserAPI, exportUserLoading} = useUser();
  const {importUserAPI,importUserLoading} = useUser();
  const [showModal, setShowModal] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [currentUser, setCurrentUser] = useState<any>();
  const [sortColumn, setSortColumn] = useState("userName");
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'
  const defaultFilter = { searchText: "", active: true, sortColumn: sortColumn ,sortDirection: sortDirection };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const [showImportFileModal, setShowImportFileModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [importErrorDetails, setImportErrorDetails] = useState([]);
  const toggleModal = (user: any) => {
    dispatch(setSelectedUser(user));
    toggleClass();
    setShowModal(!showModal);
  };
  const navigateToView = (user: any) => {
    dispatch(setSelectedUser(user));
    navigate("/view-user");
  };
  const onClose = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const viewUser = (user: any) => {
    dispatch(setSelectedUser(user));
    navigate("/view-user");
  };
  const editUser = (user: any) => {
    dispatch(setSelectedUser(user));
    navigate("/edit-user");
  };
  const deleteUser = async () => {
    await deleteUserAPI({ id: currentUser.id, deletedBy: loginUserId });
    onClose();
    fetchUserList();
  };
  const updateStatus = async (user: any) => {
    if (user.isActive) {
      await setUserInActive({ id: user.id, updatedBy: loginUserId ?? "" });
    } else {
      await setUserActive({ id: user.id, updatedBy: loginUserId ?? "" });
    }
    fetchUserList();
  };
  const fetchUserList = () => {
    getUserList(searchFieldVal).then((res: any) => {
      if (res) {
        setUsersList(res);
      }
    });
  };
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const toggleDuplicateModal = () => {
    toggleClass();
    setShowDuplicateModal(!showDuplicateModal);
  };
  const handleSort = (columnName:string) => {
    // If the same column is clicked again, toggle the sort direction
    if (sortColumn === columnName) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      setSearchFieldVal({ ...searchFieldVal, sortColumn: columnName ,sortDirection: sortDirection === 'asc' ? 'desc' : 'asc' });
    } 
    else {
      setSortColumn(columnName);
      setSortDirection('asc'); // Default to ascending when a new column is clicked
      setSearchFieldVal({ ...searchFieldVal, sortColumn: columnName ,sortDirection: 'asc' });
    }
  };

  const handleExportUserList = async() => {
    const response = await exportUserAPI();
    const url = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${localStorage.getItem("profileClientId")?.toUpperCase() ?? ""}_Users_List_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
    document.body.appendChild(link);
    link.click();
  };
  const toggleImportXlsxModal = () => {
    setSelectedFile(null);
    toggleClass();
    setImportErrorDetails([]);
    setShowImportFileModal(!showImportFileModal);
  };
  const handleImportList = async(file:any)=>{
    const formData = new FormData();
    formData.append("file", file || "");
    const response = await importUserAPI({'file':formData,'createdBy':loginUserId});
    if (response && response.data?.status !== 200 && response.data?.result?.isSuccess === false) {
      toast.error("Error!. File imported unsuccessfully");
      const errorData = response.data?.result?.data?.filter((data:any) => data.isValid === false);
      setImportErrorDetails(errorData);
    }else{
      toast.success("Success!. File imported successfully");
      toggleImportXlsxModal();
      fetchUserList();
    }
  }

  useEffect(() => {
    dispatch(setSelectedUser(null));
    fetchUserList();
  }, [searchFieldVal]);

  return (
    <div className="user-listing-wrapper">
      <CommonHeaderWithImpoExpo
        title={"Users"}
        buttonTitle="Add User"
        href="/create-user"
        disRecords={usersList.length || 0}
        totalRecords={usersList.length || 0}
        searchVal={searchFieldVal}
        setSearchVal={setSearchFieldVal}
        defaultFilter={defaultFilter}
        isAddPermitted={true}
        exportList = {handleExportUserList}
        importList = {handleImportList}
        showImportFileModal={showImportFileModal}
        setShowImportFileModal={setShowImportFileModal}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        toggleImportXlsxModal={toggleImportXlsxModal}
        importErrorDetails={importErrorDetails}
      />
      <div className="listing-table-wrapper">
        {getUsersListLoading ||
        setUserStatusActiveLoading ||
        setUserStatusDeActiveLoading ||
        deleteUserLoading ||
        exportUserLoading ||
        importUserLoading ? (
          <Loading />
        ) : null}
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              {/* <th>SRX ID</th> */}
              <th onClick={() => handleSort('fName')}>First Name {sortColumn === 'fName' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
              <th onClick={() => handleSort('mi')}>M.I {sortColumn === 'mi' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
              <th onClick={() => handleSort('lName')}>Last Name {sortColumn === 'lName' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
              <th onClick={() => handleSort('userName')}>User Name {sortColumn === 'userName' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
              <th>Email</th>
              <th>Status</th>
              <th>Access Role</th>
              <th>Clinical Role</th>
              <th onClick={() => handleSort('deaNumber')}>NPI {sortColumn === 'deaNumber' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {usersList?.map((x: any) => (
              <tr
                key={x.id}
                onDoubleClick={() => {
                  navigateToView(x);
                }}
                style={
                  x?.role?.roleName === "SysAdmin"
                    ? {
                        pointerEvents: "none",
                        opacity: 0.5,
                      }
                    : {}
                }
              >
                {/* <td>{x.id}</td> */}
                <td>{x.fName}</td>
                <td>{x.mi || "-"}</td>
                <td>{x.lName}</td>
                <td>{x.userName}</td>
                <td>{x.email || "-"}</td>
                <td>
                  <span
                    className={`status-circle ${x.isActive ? "active" : "inactive"}`}
                  >
                    {x.isActive ? "Active" : "InActive"}
                  </span>
                </td>
                <td>{x.role.roleName}</td>
                <td>{x.clinicalRole.roleName}</td>
                <td>{x.deaNumber || "NA"}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          viewUser(x);
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={viewicon}
                        />
                        View
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          updateStatus(x);
                        }}
                        style={
                          loginUserId === x.id
                            ? {
                                pointerEvents: "none",
                                opacity: 0.5,
                              }
                            : {}
                        }
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deactivateicon}
                        />
                        {x.isActive ? "Deactivate" : "Activate"}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          editUser(x);
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentUser(x);
                          toggleDuplicateModal();
                        }}
                        style={
                          loginUserId === x.id
                            ? {
                                pointerEvents: "none",
                                opacity: 0.5,
                              }
                            : {}
                        }
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={duplicateicon}
                        />
                        Duplicate User
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentUser(x);
                          toggleModal(x);
                        }}
                        style={
                          loginUserId === x.id
                            ? {
                                pointerEvents: "none",
                                opacity: 0.5,
                              }
                            : {}
                        }
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={onClose}
        deleteAction={deleteUser}
        modelName={`User`}
        recordName={currentUser?.email || ""}
      />
      <DublicateUserModel
        selectedUser = {currentUser}
        showModal = {showDuplicateModal}
        toggleDuplicateModal = {toggleDuplicateModal}
        reloadListing = {fetchUserList}
        userList ={usersList}
      />
    </div>
  );
};

export default UserListing;
