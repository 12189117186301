import { useState } from "react";
import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import Select from "react-select";
import TelerikReporting from "../../../components/TelerikReporting/TelerikReporting";
import ReportHeader from "../../../components/reportHeader/ReportHeader";
import useReports from "../../../Hooks/useReports";
import Loading from "../../../components/LoadingPage/Loading";
import moment from "moment";

const providerStatusList =[
  { value:true, label:"Active"},
  { value:false, label:"Inactive"},
]
const ProvidersList = () => {
    const [reportKey, setReportKey] = useState(0);
    const [isActive, setIsActive] = useState({value: true ,label: "Active"});
    const { providerListExportAPI, providerListExportLoading} = useReports();

    const handleProvidersListExport = async ()=>{
    const response = await providerListExportAPI({
       isActive: isActive?.value ?? true
    });
    const url = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${localStorage.getItem("profileClientId")?.toUpperCase() ?? ""}_Providers_List_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }

  return (
  <>
    <div className="report-content-wrapper">
       <ReportHeader
        reportTitle="SRX Report - List of Providers"
        // reportDate="04 April 2024"
      />
      <div className="report-filter-wrapper">
        <div className="report-filter-item-wrapper">
          { providerListExportLoading ?  <Loading /> : null}
           <div className="filter-item-box location-filter-item">
            <Select 
              classNamePrefix="react-select"
              options={providerStatusList}
              value={providerStatusList?.find(
              (option: any) => isActive?.value=== option.value
              )}
              onChange={(option: any) => {
              setIsActive(option);
              setReportKey((prevKey) => prevKey + 1);
              }}
            />
          </div>
        </div>
        <div className="report-export-box">
          <button type="button" className="report-export-btn" onClick={handleProvidersListExport}>
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
            Export
          </button>
        </div>
      </div>
       <div className="filter-detail-wrapper">
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-title">
            <h4></h4>
          </div>
          <div className="providerlist-report-wrapper">
             <TelerikReporting 
                key={reportKey}
                params={{
                  isActive: isActive?.value ?? true,
                  isExport: "false",
                }} 
                reportName="ListOfProvidersReport.trdp" 
                reportEndPoint="reports/listofproviders/get" 
              />
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ProvidersList;
