import "./athenatransactions.scss";
import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { Dropdown, Form, Table } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import exporticon from "../../assets/images/export-icon.svg";
import threedotvertical from "../../assets/images/three-dots-vertical.svg";
import viewicon from "../../assets/images/view-icon.svg";
import refreshicon from "../../assets/images/refresh-icon.svg";
import Select from "react-select";
import { formatDateForAthena } from "../../utils/commonUtils";
import useAthena from "../../Hooks/useAthena";
import { FormikHelpers, useFormik } from "formik";
import { DropDownValues } from "../../utils/types";
import Loading from "../../components/LoadingPage/Loading";
import { AthenaList } from "../../redux/athena/athenaApiSlice";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import { useNavigate } from "react-router";
import moment from "moment";

const AthenaTransactions = () => {
  const [statusOptions, setStatusOptions] = useState<DropDownValues[]>([]);
  const [locationOptions, setLocationOptions] = useState<DropDownValues[]>([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [athenaList, setAthenaList] = useState<AthenaList[]>([]);
  const navigate = useNavigate();
  const DynamicInitialValues = {
    orderTypes: [],
    status: "",
    locationId: "",
    encounterId: "",
    athenaId: "",
    message: "",
    loadAllVersions: true,
    fromDate: null,
    toDate: null,
  };
  const [formInitialValues, setFormInitialValues] =
    useState(DynamicInitialValues);
  const { getathenaTransDDAPI, athenaTransDDLoading } = useAthena();
  const { getAthenaTransListAPI, 
    athenaTransListLoading, 
    exportAthenaTransactionsAPI,
    exportAthenaTransactionLoading 
  } = useAthena();
  const { athenaLocationsAPI, athenaLocationsLoading } = useAthena();
  const { athenaProvidersAPI, athenaProvidersLoading } = useAthena();
  const { athenaOrdersAPI, athenaOrdersLoading } = useAthena();
  useEffect(() => {
    getathenaTransDDAPI({}).then(async (res: any) => {
      if (res) {
        setStatusOptions(
          res.statuses?.map((option: any) => {
            return {
              value: option.key,
              label: option.value,
            };
          }),
        );
        setLocationOptions(
          res.locations?.map((option: any) => {
            return {
              value: option.key,
              label: option.value,
            };
          }),
        );
        setTypeOptions(
          await res.orderTypes
            ?.map((option: any) => {
              return {
                value: option.key,
                label: option.value,
              };
            })
            .filter(
              (option: any) => option.label !== "All" && option.value !== "",
            ),
        );
      } else {
        setLocationOptions([]);
        setTypeOptions([]);
        setStatusOptions([]);
      }
    });
  }, []);
  useEffect(() => {
    getAthenaTransList();
  }, []);
  const getAthenaTransList = () => {
    getAthenaTransListAPI(values).then((res: any) => {
      if (res) {
        setAthenaList(res);
      }
    });
  };

  const { handleChange, values, handleSubmit, setFieldValue, resetForm } =
    useFormik({
      initialValues: formInitialValues,
      onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
        try {
          getAthenaTransList();
        } catch (err) {}
        setSubmitting(false);
      },
      enableReinitialize: true,
    });
  const clearFilter = () => {
    resetForm();
    getAthenaTransList();
  };
  const handleSelectAll = (items: any) => {
    setSelectedOptions(items);
    const containsAllLabel = items.length === typeOptions.length;
    if (containsAllLabel) {
      setFieldValue("orderTypes", []);
    } else {
      setFieldValue(
        "orderTypes",
        items.map((item: DropDownValues) => item.value),
      );
    }
  };
  const handleExportAthinaTrancationList = async() => {
      const response = await exportAthenaTransactionsAPI(values);
      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${localStorage.getItem("profileClientId")?.toUpperCase() ?? ""}_Athena_Transactions_List_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
      document.body.appendChild(link);
      link.click();
    };
  return (
    <div className="athena-trans-content-wrapper">
      {athenaTransDDLoading ||
      athenaTransListLoading ||
      athenaLocationsLoading ||
      athenaProvidersLoading ||
      exportAthenaTransactionLoading ||
      athenaOrdersLoading ? (
        <Loading />
      ) : null}
      <div className="athena-trans-header">
        <div className="title-content">
          <h2>Athena Transactions</h2>
          <p></p>
        </div>
        <div className="athena-load-options">
          <div
            className="athena-load-options-box load-location"
            title="Loads missing locations from Athena"
            onClick={(e) => athenaLocationsAPI("")}
          >
            Locations
          </div>
          <div
            className="athena-load-options-box load-providers"
            title="Loads missing providers and patients from Athena"
            onClick={(e) => athenaProvidersAPI("")}
          >
            Providers
          </div>
          <div
            className="athena-load-options-box load-orders"
            onClick={(e) => athenaOrdersAPI("")}
          >
            Athena Orders
          </div>
        </div>
      </div>
      <div className="report-filter-wrapper">
        <Form>
          <div className="report-filter-item-wrapper">
            <Form.Group className="filter-item-box">
              <Form.Label className="form-label">Status</Form.Label>
              <Select
                isSearchable={true}
                classNamePrefix="react-select"
                options={statusOptions}
                className="react-select-container"
                id="status"
                name="status"
                value={statusOptions?.find(
                  (option: DropDownValues) => option.value === values.status,
                )}
                onChange={(option: any) => {
                  handleChange("status")(option.value);
                  handleSubmit();
                }}
              />
            </Form.Group>
            <Form.Group className="filter-item-box">
              <Form.Label className="form-label">Start Date</Form.Label>
              <div className="calender-box">
                <DatePicker
                  format="MM/dd/yyyy"
                  openCalendarOnFocus={false}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  className="form-control"
                  showLeadingZeros={true}
                  value={values.fromDate}
                  clearIcon={null}
                  calendarIcon={<i className="bi bi-calendar"></i>}
                  onChange={(date: any) => {
                    handleChange({
                      target: {
                        name: "fromDate",
                        value: moment(date).format("YYYY-MM-DD"),
                      },
                    });
                    handleSubmit();
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group className="filter-item-box">
              <Form.Label className="form-label">End Date</Form.Label>
              <div className="calender-box">
                <DatePicker
                  format="MM/dd/yyyy"
                  openCalendarOnFocus={false}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  className="form-control"
                  showLeadingZeros={true}
                  value={values.toDate}
                  clearIcon={null}
                  calendarIcon={<i className="bi bi-calendar"></i>}
                  onChange={(date: any) => {
                    handleChange({
                      target: {
                        name: "toDate",
                        value: moment(date).format("YYYY-MM-DD"),
                      },
                    });
                    handleSubmit();
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group className="filter-item-box location-filter-item">
              <Form.Label className="form-label">Location</Form.Label>
              <Select
                classNamePrefix="react-select"
                options={locationOptions}
                className="react-select-container"
                id="locationId"
                name="locationId"
                value={locationOptions?.find(
                  (option: DropDownValues) =>
                    option.value === values.locationId,
                )}
                onChange={(option: any) => {
                  handleChange("locationId")(option.value);
                  handleSubmit();
                }}
              />
            </Form.Group>
            <Form.Group className="filter-item-box type-filter-item">
              <Form.Label className="form-label">Type</Form.Label>
              <MultiSelect
                classNamePrefix="react-select"
                className="react-select-multiselect"
                key="orderTypes"
                options={typeOptions}
                onChange={(option: any) => {
                  handleSelectAll(option);
                  handleSubmit();
                }}
                isClearable={false}
                value={selectedOptions}
                isSelectAll={true}
                menuPlacement={"bottom"}
              />
            </Form.Group>
            <Form.Group className="filter-item-box">
              <Form.Label className="form-label">
                Athena ID
              </Form.Label>
              <Form.Control
                type="text"
                id="athenaId"
                name="athenaId"
                onChange={(e) => {
                  handleChange("athenaId")(e.target.value ?? "");
                  handleSubmit();
                }}
                value={values.athenaId}
                placeholder="ATH. ID"
              />
            </Form.Group>
            <Form.Group className="filter-item-box">
              <Form.Label className="form-label">
              Encounter ID
              </Form.Label>
              <Form.Control
                type="text"
                id="encounterId"
                name="encounterId"
                onChange={(e) => {
                  handleChange("encounterId")(e.target.value ?? "");
                  handleSubmit();
                }}
                value={values.encounterId}
                placeholder="ENC. ID"
              />
            </Form.Group>
          </div>
          <div className="report-filter-item-wrapper">
            <Form.Group className="filter-item-box z-1">
              <Form.Label className="form-label">Message</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                placeholder="Enter Message"
                id="message"
                name="message"
                onChange={(e) => {
                  handleChange("message")(e.target.value ?? "");
                  handleSubmit();
                }}
                value={values.message}
              />
            </Form.Group>
            <div className="filter-item-box clear-all-box z-1">
              <button
                type="button"
                className="clear-filter-btn"
                onClick={clearFilter}
              >
                Clear All
              </button>
            </div>
          </div>
        </Form>
        <div className="report-export-box">
          <button type="button" className="report-export-btn"  onClick={()=> getAthenaTransList()} title="Refresh"> 
            <ReactSVG className="svg-box" wrapper="span" src={refreshicon} />
          </button>
          <button type="button" className="report-export-btn"  onClick={handleExportAthinaTrancationList}>
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />
            Export
          </button>
        </div>
      </div>
      <div className="filter-detail-wrapper">
        <div
          className="filter-item-detail-wrapper"
          style={{ marginTop: "14px" }}
        >
          <span style={{ fontSize: "12px", color: "#A6A6A6" }}>
            Displaying {athenaList?.length || 0} of {athenaList?.length || 0}{" "}
            Results
          </span>
          <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Type</th>
                  <th>LOCATION</th>
                  <th>Status</th>
                  <th>Patient Name</th>
                  <th>internal Note/ barcode</th>
                  <th>message</th>
                  <th>SRX Status</th>
                  <th>ATH.ID</th>
                  <th>ENC ID</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {athenaList?.map((x: AthenaList) => (
                  <tr
                    key={x.id}
                    onDoubleClick={() => navigate(`/view-transaction/${x.id}`)}
                  >
                    <td
                      title={`${formatDateForAthena(x.dateCreatedForDetail || x.dateModifiedForDetail || x.dateCreated || x.dateModified)}`}
                    >{`${formatDateForAthena(x.dateCreatedForDetail || x.dateModifiedForDetail || x.dateCreated || x.dateModified)}`}</td>
                    <td title={`${x.orderType} - ${x.orderingMode}`}>
                      {x.orderType}-{x.orderingMode}
                    </td>
                    <td
                      title={
                        locationOptions.find(
                          (location: DropDownValues) =>
                            x.departmentId == location.value,
                        )?.label ?? ""
                      }
                    >
                      {locationOptions.find(
                        (location: DropDownValues) =>
                          x.departmentId == location.value,
                      )?.label ?? ""}
                    </td>
                    <td title={x.status}>{x.status}</td>
                    <td
                      title={`${x.athenaPatient.fName} ${x.athenaPatient.lName}`}
                    >{`${x.athenaPatient.fName} ${x.athenaPatient.lName}`}</td>
                    <td title={x.internalNote || "-"}>
                      {x.internalNote || "-"}
                    </td>
                    <td title={x.srxMessage}>{x.srxMessage}</td>
                    <td>
                      <span className="status-tag active">
                        {
                          statusOptions.find(
                            (option: DropDownValues) =>
                              option.value === x.srxStatus,
                          )?.label
                        }
                      </span>
                    </td>
                    <td title={`${x.athenaId}`}>{x.athenaId}</td>
                    <td title={x.encounterId}>{x.encounterId}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="link" id="dropdown-basic">
                          <ReactSVG
                            className="svg-box"
                            wrapper="span"
                            src={threedotvertical}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href={`/view-transaction/${x.id}`}>
                            <ReactSVG
                              className="svg-box"
                              wrapper="span"
                              src={viewicon}
                            />
                            Order Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AthenaTransactions;
