import { ReactSVG } from "react-svg";
import { Dropdown, Table } from "react-bootstrap";
import filtericon from "../../../../assets/images/filter-icon.svg";
import editicon from "../../../../assets/images/edit-icon.svg";
import printicon from "../../../../assets/images/print-icon.svg";
import defaulticon from "../../../../assets/images/default-icon.svg";
import threedotvertical from "../../../../assets/images/three-dots-vertical.svg";
import deactivateicon from "../../../../assets/images/deactivate-icon.svg";
import duplicateicon from "../../../../assets/images/duplicate-icon.svg";
import useInventory from "../../../../Hooks/useInventory";
import { useEffect, useState } from "react";
import { CreateBarcodeModel } from "./popUpModels/createBarcodeModel";
import { selectCurrentUserId } from "../../../../redux/auth/authSlice";
import { useAppSelector } from "../../../../redux/hooks";
import { toggleClass } from "../../../../utils/commonUtils";
import Loading from "../../../../components/LoadingPage/Loading";
import { EditBarcodeModel } from "./popUpModels/EditBarcodeModel";
import { Barcode } from "../../../../redux/inventory/inventorySlice";
import { PrintBarcodePopUp } from "../printBarcodePopUp/printBarcodePopUp";

export const BarcodeManagementTab = ({ selectedItem, printBarcode, selectedLabel }: any) => {
  const [showBarcodeModal, setShowBarcodeModal] = useState(false);
  const [defaultBarcodes, setDefaultBarcodes] = useState<Barcode>();
  const [getBarcodesList, setGetBarcodesList] = useState([]);
  const { getBarcodesAPI, getBarcodesLoading } = useInventory();
  const [showCreateBarcodeModal, setshowCreateBarcodeModal] = useState(false);
  const [showEditBarcodeModal, setShowEditBarcodeModal] = useState(false);
  const [newBarcode, setNewBarcode] = useState("");
  const { getNewBarcodeAPI, getNewBarcodeLoading } = useInventory();
  const { barcodeCreateAPI, barcodeCreateLoading } = useInventory();
  const { barcodeEditAPI, barcodeEditLoading } = useInventory();
  const { barcodeDefaultAPI, barcodeDefaultLoading } = useInventory();
  const [currentRecord, setCurrentRecord] = useState<any>();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const toggleBarcodeModal = () => {
    toggleClass();
    setShowBarcodeModal(!showBarcodeModal);
  };

  const toggleCreateBarcodeModal = () => {
    toggleClass();
    if (!showCreateBarcodeModal) {
      getNewBarcodeAPI().then((res: any) => setNewBarcode(res));
    } else {
      setNewBarcode("");
    }
    setshowCreateBarcodeModal(!showCreateBarcodeModal);
  };
  const toggleEditBarcodeModal = () => {
    toggleClass();
    setShowEditBarcodeModal(!showEditBarcodeModal);
  };
  const getBarcodeList = () => {
    getBarcodesAPI(selectedItem.id).then((res: any) => {
      if (res) {
        setGetBarcodesList(res);
      }
    });
  };
  useEffect(() => {
    getBarcodeList();
  }, []);

  const createBarcode = (data: any) => {
    if (getBarcodesList.length > 0) {
      barcodeCreateAPI(data).then(getBarcodeList);
    } else {
      barcodeCreateAPI({ ...data, isDefault: true }).then(getBarcodeList);
    }
    toggleCreateBarcodeModal();
  };
  const editBarcode = (data: Barcode) => {
    if (data.isDefault) {
      getDefaultBarcodes();
    }
    barcodeEditAPI(data).then(getBarcodeList);
    toggleEditBarcodeModal();
  };
  const getDefaultBarcodes = () => {
    const defaultBarcodes = getBarcodesList.filter(
      (item: Barcode) => item.isDefault,
    );
    defaultBarcodes.forEach(async (item: Barcode) => {
      await barcodeEditAPI({ ...item, isDefault: false });
    });
    if (defaultBarcodes.length > 0) {
      getBarcodeList();
    }
  };
  const makeDefault = async (data: any) => {
    try {
      getDefaultBarcodes();
      barcodeDefaultAPI({
        id: data.id,
        updatedBy: currentUserId,
        isDefault: true,
      }).then(getBarcodeList);
    } catch {}
  };
  const updateStatus = async (data: any) => {
    barcodeEditAPI({ ...data, isActive: !data.isActive }).then(getBarcodeList);
  };
  const handleCopy = async (rxSRXId: string) => {
    await navigator.clipboard.writeText(rxSRXId);
  };
  return (
    <>
      <div className="inventory-tab-head">
        {getNewBarcodeLoading ||
        getBarcodesLoading ||
        barcodeCreateLoading ||
        barcodeEditLoading ||
        barcodeDefaultLoading ? (
          <Loading />
        ) : null}
        <h4>
          Displaying {getBarcodesList.length} of {getBarcodesList.length}{" "}
          Results
        </h4>
        <div className="inventory-tab-action-wrapper">
          <div
            className="inventory-tab-action-box inventory-tab-action-trans-report"
            onClick={toggleCreateBarcodeModal}
          >
            <i className="bi bi-plus"></i> Add New
          </div>
          {/* <div className="inventory-tab-action-box inventory-tab-action-filter">
            <ReactSVG className="svg-box" wrapper="span" src={filtericon} />
          </div> */}
        </div>
      </div>
      <div className="inventory-profile-table-wrapper">
        <Table bordered={false} hover={true}>
          <thead>
            <tr>
              <th>BARCODE</th>
              <th>STATUS</th>
              <th>DEFAULT</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getBarcodesList?.map((x: any) => (
              <tr key={x.id}>
                <td>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {x.rxSRXId ?? ""}
                    {x.isDefault && (
                      <span
                        className="status-box active"
                        style={{ marginLeft: "5px" }}
                      >
                        Default
                      </span>
                    )}
                  </span>
                </td>
                <td>
                  <span
                    className={`status-circle  ${x.isActive ? "active" : "inactive"}`}
                  ></span>
                  {x.isActive ? "Active" : "InActive"}
                </td>
                <td>{x.isDefault ? "Yes" : "No"}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          handleCopy(x.rxSRXId);
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={duplicateicon}
                        />
                        Copy
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          updateStatus(x);
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deactivateicon}
                        />
                        {x.isActive ? "Deactivate" : "Activate"}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentRecord(x);
                          toggleEditBarcodeModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          // setDefaultBarcodes(x);
                          // toggleBarcodeModal();
                          printBarcode(selectedLabel?.value, selectedItem, x.rxSRXId);
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={printicon}
                        />
                        Print Barcode
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => makeDefault(x)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={defaulticon}
                        />
                        Make Default
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <PrintBarcodePopUp
        showBarcodeModal={showBarcodeModal}
        toggleBarcodeModal={toggleBarcodeModal}
        printBarcode={printBarcode}
        defaultBarcodes={defaultBarcodes}
      />
      {showCreateBarcodeModal && (
        <CreateBarcodeModel
          selectedItem={selectedItem}
          selectCurrentUserId={currentUserId}
          toggleCreateBarcodeModal={toggleCreateBarcodeModal}
          createBarcode={createBarcode}
          newBarcode={newBarcode}
        />
      )}
      {showEditBarcodeModal && (
        <EditBarcodeModel
          currentRecord={currentRecord}
          currentUserId={currentUserId}
          toggleEditBarcodeModal={toggleEditBarcodeModal}
          editBarcode={editBarcode}
        />
      )}
    </>
  );
};
