import { Dropdown, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import receiveicon from "../../../../assets/images/receive-icon.svg";
import adjusticon from "../../../../assets/images/adjust-icon.svg";
import transfericon from "../../../../assets/images/transfer-icon.svg";
import refreshicon from "../../../../assets/images/refresh-icon.svg";
import reporticon from "../../../../assets/images/report-icon.svg";
import threedotvertical from "../../../../assets/images/three-dots-vertical.svg";
import { useEffect, useState } from "react";
import useInventory from "../../../../Hooks/useInventory";
import { toggleClass } from "../../../../utils/commonUtils";
import Loading from "../../../../components/LoadingPage/Loading";
import { selectCurrentUserId } from "../../../../redux/auth/authSlice";
import { InventoryReceiveModel } from "./popUpModels/inventoryReceiveModel";
import { InventoryAdjustModel } from "./popUpModels/inventoryAdjustModel";
import { InventoryTransferModel } from "./popUpModels/inventoryTransferModel";
import { useAppSelector } from "../../../../redux/hooks";
import useLocations from "../../../../Hooks/useLocations";
import { InventoryRepackModel } from "./popUpModels/inventoryRepackModel";
import { InventoryCountModel } from "./popUpModels/inventoryCountModel";
import moment from "moment";
import React from "react";
export const InventoryManagementTab = ({
  onShowReportModal,
  selectedItem,
  refreshOMTab,
}: any) => {
  const { getLocationsAPI, getLocationsLoading } = useLocations();
  const [showInventoryReceiveModal, setShowInventoryReceiveModal] =
    useState(false);
  const [showInventoryRepackModal, setShowInventoryRepackModal] =
    useState(false);
  const [showInventoryCountModal, setShowInventoryCountModal] = useState(false);
  const [showInventoryAdjustModal, setShowInventoryAdjustModal] =
    useState(false);
  const [showInventoryTransferModal, setShowInventoryTransferModal] =
    useState(false);
  const [inventoryTransactionsList, setInventoryTransactionsList] = useState(
    [],
  );
  const [adjustmentTransactionList,setAdjustmentTransactionList]= useState([]);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [locationDropDownVal, setLocationDropDownVal] = useState([]);

  const { getInventoryCountListAPI, getInventoryCountListLoading } =
    useInventory();
  const { getInventoryTransactions, getInventoryTransactionsLoading } =
    useInventory();

  const [inventoryCountList, setInventoryCountList] = useState<any[]>();
  const [activeLocation, setActiveLocation] = useState("");

  const getInvCountList = () => {
    getInventoryCountListAPI(selectedItem.id).then((res: any) => {
      if (res) {
        setInventoryCountList(res);
        refreshOMTab(res);
      }
    });
  };
  useEffect(() => {
    getInvCountList();
    getLocationsAPI({ searchText: "", includeDeleted: false, active: true })
      .then((res: any) => {
        setLocationDropDownVal(
          res?.map((option: any) => {
            return {
              value: option.id,
              label: option.locationName,
            };
          }),
        );
      })
      .catch();
    getInventoryTransactions().then((res: any) => {
      if (res) {
        setInventoryTransactionsList(
          res.map((option: any) => {
              return {
                value: option.id,
                label: option.transactionName,
              };
            }),
        );
        setAdjustmentTransactionList(
          res
            .filter((option: any) => option.transactionGroup === 1 && option.isDisplayed === true)
            .map((option: any) => {
              return {
                value: option.id,
                label: option.transactionName,
              };
            }),
        );
      }
    });
  }, []);

  const toggleInventoryreceiveModal = () => {
    if (showInventoryReceiveModal) setActiveLocation("");
    toggleClass();
    setShowInventoryReceiveModal(!showInventoryReceiveModal);
  };
  const toggleInventoryrepackModal = () => {
    if (showInventoryRepackModal) setActiveLocation("");
    toggleClass();
    setShowInventoryRepackModal(!showInventoryRepackModal);
  };

  const toggleInventoryCountModal = () => {
    if (showInventoryCountModal) setActiveLocation("");
    toggleClass();
    setShowInventoryCountModal(!showInventoryCountModal);
  };

  const toggleInventoryAdjustModal = () => {
    if (showInventoryAdjustModal) setActiveLocation("");
    toggleClass();
    setShowInventoryAdjustModal(!showInventoryAdjustModal);
  };
  const toggleInventoryTransferModal = () => {
    if (showInventoryTransferModal) setActiveLocation("");
    toggleClass();
    setShowInventoryTransferModal(!showInventoryTransferModal);
  };

  const getTotal = () => {
    let sum = 0;
    inventoryCountList?.forEach((x) => {
      sum = sum + parseFloat(x.quantity);
    });
    return parseFloat(sum.toString()).toFixed(2);
  };
  const { inventoryTransAdjust, inventoryTransactionsAdjustLoading } =
    useInventory();
  const invTransAdjust = (values: any) => {
    inventoryTransAdjust(values)
      .then(() => {
        toggleInventoryAdjustModal();
        getInvCountList();
      })
      .catch();
  };
  const { inventoryTransReceive, inventoryTransactionsReceiveLoading } =
    useInventory();
  const invTransReceive = (values: any) => {
    inventoryTransReceive(values)
      .then(() => {
        toggleInventoryreceiveModal();
        getInvCountList();
      })
      .catch();
  };
  const { inventoryTransTransfer, inventoryTransactionsTransferLoading } =
    useInventory();
  const invTransTransfer = (values: any) => {
    inventoryTransTransfer(values)
      .then(() => {
        toggleInventoryTransferModal();
        getInvCountList();
      })
      .catch();
  };

  const { inventoryTransCount, inventoryTransactionsCountLoading } =
    useInventory();
  const invTransCount = (values: any) => {
    inventoryTransCount(values)
      .then(() => {
        toggleInventoryCountModal();
        getInvCountList();
      })
      .catch();
  };
  const { inventoryTransRepack, inventoryTransactionsRepackLoading } =
    useInventory();
  const invTransRepack = (values: any) => {
    inventoryTransRepack(values)
      .then(() => {
        toggleInventoryrepackModal();
        getInvCountList();
      })
      .catch();
  };
  const getLoadingState = () => {
    return (
      getInventoryCountListLoading ||
      inventoryTransactionsAdjustLoading ||
      inventoryTransactionsTransferLoading ||
      inventoryTransactionsReceiveLoading ||
      getInventoryTransactionsLoading ||
      inventoryTransactionsCountLoading ||
      getLocationsLoading ||
      inventoryTransactionsRepackLoading
    );
  };
  return (
    <>
      {getLoadingState() ? <Loading /> : null}
      <div className="inventory-tab-head">
        <h4>
          Displaying {inventoryCountList?.length || 0} of{" "}
          {inventoryCountList?.length || 0} Results
        </h4>
        <div className="inventory-tab-action-wrapper">
          <div className="inventory-tab-action-box inventory-tab-more-action-wrapper">
            <div
              className="invt-receive-icon-box"
              onClick={toggleInventoryreceiveModal}
            >
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Receive</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <ReactSVG
                  className="svg-box"
                  wrapper="span"
                  src={receiveicon}
                />
              </OverlayTrigger>
            </div>

            <div
              className="invt-adjust-icon-box"
              onClick={toggleInventoryAdjustModal}
            >
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Adjust</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <ReactSVG className="svg-box" wrapper="span" src={adjusticon} />
              </OverlayTrigger>
            </div>

            <div
              className="invt-transfer-icon-box"
              onClick={toggleInventoryTransferModal}
            >
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Transfer</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <ReactSVG
                  className="svg-box"
                  wrapper="span"
                  src={transfericon}
                />
              </OverlayTrigger>
            </div>
            <div className="invt-refresh-icon-box" onClick={getInvCountList}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Refresh</Tooltip>}
                trigger={["hover", "focus"]}
              >
                <ReactSVG
                  className="svg-box"
                  wrapper="span"
                  src={refreshicon}
                />
              </OverlayTrigger>
            </div>
          </div>
          <div className="inventory-tab-action-box inventory-tab-action-dropdown dropdown">
            <span
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Actions <i className="bi bi-chevron-down"></i>
            </span>
            <ul className="dropdown-menu">
              <li onClick={toggleInventoryrepackModal}>Repack</li>
              <li onClick={toggleInventoryCountModal}>Count</li>
            </ul>
          </div>
          <div
            className="inventory-tab-action-box inventory-tab-action-trans-report"
            onClick={onShowReportModal}
          >
            <ReactSVG className="svg-box" wrapper="span" src={reporticon} />{" "}
            Transaction Report
          </div>
          {/* <div className="inventory-tab-action-box inventory-tab-action-filter">
            <ReactSVG className="svg-box" wrapper="span" src={filtericon} />
          </div> */}
        </div>
      </div>
      <div className="inventory-profile-table-wrapper">
        <Table bordered={false} hover={true}>
          <thead>
            <tr>
              <th>LOCATION</th>
              <th style={{ textAlign: "center" }}>
                Quantity ({selectedItem.unit.unitName})
              </th>
              <th>VERIFIED DATE (BY)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {inventoryCountList?.map((x: any) => (
              <tr key={x.locationId}>
                <td>{x.location.locationName}</td>
                <td style={{ textAlign: "center" }}>{parseFloat(x?.quantity?.toString()).toFixed(2)}</td>
                <td>{`${x?.verifyDate ? moment(x.verifyDate).format("MM/DD/YYYY") : "-"} ${x.user ? `(${x.user.userName})` : ""}`}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setActiveLocation(x.location.id);
                          toggleInventoryreceiveModal();
                        }}
                      >
                        Receive
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setActiveLocation(x.location.id);
                          toggleInventoryAdjustModal();
                        }}
                      >
                        Adjust
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setActiveLocation(x.location.id);
                          toggleInventoryTransferModal();
                        }}
                      >
                        Transfer
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setActiveLocation(x.location.id);
                          toggleInventoryrepackModal();
                        }}
                      >
                        Repack
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setActiveLocation(x.location.id);
                          toggleInventoryCountModal();
                        }}
                      >
                        Count
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}

            <tr>
              <th>Total</th>
              <th style={{ textAlign: "center" }}>{getTotal()}</th>
              <th></th>
              <th></th>
            </tr>
          </tbody>
        </Table>
      </div>

      {showInventoryReceiveModal && (
        <InventoryReceiveModel
          locationDropDownVal={locationDropDownVal}
          selectedItem={selectedItem}
          selectCurrentUserId={currentUserId}
          toggleInventoryreceiveModal={toggleInventoryreceiveModal}
          inventoryTransactionsList={inventoryTransactionsList}
          invTransReceive={invTransReceive}
          activeLocation={activeLocation}
        />
      )}

      {showInventoryRepackModal && (
        <InventoryRepackModel
          locationDropDownVal={locationDropDownVal}
          selectedItem={selectedItem}
          selectCurrentUserId={currentUserId}
          toggleInventoryrepackModal={toggleInventoryrepackModal}
          invTransRepack={invTransRepack}
          activeLocation={activeLocation}
        />
      )}

      {showInventoryCountModal && (
        <InventoryCountModel
          locationDropDownVal={locationDropDownVal}
          selectedItem={selectedItem}
          selectCurrentUserId={currentUserId}
          toggleInventoryCountModal={toggleInventoryCountModal}
          invTransCount={invTransCount}
          inventoryTransactionsList={inventoryTransactionsList}
          inventoryCountList={inventoryCountList}
          activeLocation={activeLocation}
        />
      )}

      {showInventoryAdjustModal && (
        <InventoryAdjustModel
          locationDropDownVal={locationDropDownVal}
          selectedItem={selectedItem}
          selectCurrentUserId={currentUserId}
          toggleInventoryAdjustModal={toggleInventoryAdjustModal}
          inventoryTransactionsList={adjustmentTransactionList}
          invTransAdjust={invTransAdjust}
          activeLocation={activeLocation}
        />
      )}
      {showInventoryTransferModal && (
        <InventoryTransferModel
          locationDropDownVal={locationDropDownVal}
          selectedItem={selectedItem}
          selectCurrentUserId={currentUserId}
          toggleInventoryTransferModal={toggleInventoryTransferModal}
          inventoryTransactionsList={inventoryTransactionsList}
          invTransTransfer={invTransTransfer}
          activeLocation={activeLocation}
        />
      )}
    </>
  );
};
