import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import UserManagement from "./pages/UserManagement";
import AddUser from "./pages/UserManagements/AddUsers/AddUser";
import UserProfile from "./pages/UserManagements/Userprofile/UserProfile";
import LoginUserProfile from "./pages/LoginUserProfile/Userprofile/LoginUserProfile";
import Records from "./pages/Records";
import InventoryStatusReport from "./pages/Reports/InventoryStatusReport/InventoryStatusReport";
import InventoryExpireReport from "./pages/Reports/InventoryExpireReport/InventoryExpireReport";
import InventoryReceivingReport from "./pages/Reports/ReceivingDetailsReport/InventoryReceivingReport";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import ForgetPassword from "./pages/forgot-password/ForgetPassword";
import ResetPassword from "./pages/reset-password/ResetPassword";
import Login from "./pages/login/Login";
import { fetchConfigData } from "./redux/config/configThunk";
import { useAppDispatch } from "./redux/hooks";
import { useEffect, useState } from "react";
import Itemlisting from "./pages/InventoryManagements/Itemlisting/Itemlisting";
import AddItem from "./pages/InventoryManagements/AddInventory/AddItem";
import InventoryProfile from "./pages/InventoryManagements/InventoryProfile/InventoryProfile";
import Predictions from "./pages/Predictions/Predictions";
import Settings from "./pages/Settings/SettingListing/SettingsListing";
import UserGuide from "./pages/UserGuide/UserGuide";
import ContactUs from "./pages/ContactUs/ContactUs";
import Tickets from "./pages/Tickets/Tickets";
import AboutUs from "./pages/AboutUs/AboutUs";
import UsageDetailsReport from "./pages/Reports/UsageDetailsReport/UsageDetailsReport";
import TransferDetailsReport from "./pages/Reports/TransferDetailsReport/TransferDetailsReport";
import AdjusmentDetailsReport from "./pages/Reports/AdjusmentDetailsReport/AdjusmentDetailsReport";
import ReconReport from "./pages/Reports/ReconciliationReport/ReconReport";
import RecalledInventoryReport from "./pages/Reports/RecalledInventoryReport/RecalledInventoryReport";
import ProvidersList from "./pages/Reports/ProvidersList/ProvidersList";
import LocationsList from "./pages/Reports/LocationsList/LocationsList";
import UsersList from "./pages/Reports/UsersList/UsersList";
import MedicationsList from "./pages/Reports/MedicationsList/MedicationsList";
import LotSearchItem from "./pages/Reports/LotSearchItem/LotSearchItem";
import LotSearchPatients from "./pages/Reports/LotSearchPatients/LotSearchPatients";
import LocationListing from "./pages/LocationMaster/LocationListing/LocationListing";
import AddLocation from "./pages/LocationMaster/AddLocation/AddLocation";
import LocationProfile from "./pages/LocationMaster/LocationProfile/LocationProfile";
import Loading from "./components/LoadingPage/Loading";
import ItemTypeListing from "./pages/ItemTypeMaster/ItemTypeListing/ItemTypeListing";
import ItemTypeProfile from "./pages/ItemTypeMaster/ItemTypeProfile/ItemTypeProfile";
import AddItemType from "./pages/ItemTypeMaster/AddItemType/AddItemType";
import UnitListing from "./pages/UnitMaster/UnitListing/UnitListing";
import AddUnit from "./pages/UnitMaster/AddUnit/AddUnit";
import UnitProfile from "./pages/UnitMaster/UnitProfile/UnitProfile";
import AddSetting from "./pages/Settings/AddSetting/AddSetting";
import SettingProfile from "./pages/Settings/SettingProfile/SettingProfile";
import StockListing from "./pages/StockMaster/StockListing/StockListing";
import StockProfile from "./pages/StockMaster/StockProfile/StockProfile";
import AddStock from "./pages/StockMaster/AddStock/AddStock";
import RoleListing from "./pages/RolePermission/RoleListing/RoleListing";
import AddRole from "./pages/RolePermission/AddRole/AddRole";
import AddUserProfile from "./pages/LoginUserProfile/AddUserProfile/AddUserProfile";
import VendorListing from "./pages/VendorMaster/VendorListing/VendorListing";
import VendorProfile from "./pages/VendorMaster/VendorProfile/VendorProfile";
import AddVendor from "./pages/VendorMaster/AddVendor/AddVendor";
import OrderingProductListing from "./pages/OrderingProductMaster/OrderingProductListing/OrderingProductListing";
import OrderingProductProfile from "./pages/OrderingProductMaster/OrderingProductProfile/OrderingProductProfile";
import AddOrderingProduct from "./pages/OrderingProductMaster/AddOrderingProduct/AddOrderingProduct";
import ProductMasterListing from "./pages/ProductMaster/ProductMasterListing/ProductMasterListing";
import ProductMasterProfile from "./pages/ProductMaster/ProductMasterProfile/ProductMasterProfile";
import AddProductMaster from "./pages/ProductMaster/AddProductMaster/AddProductMaster";
import PatientsListing from "./pages/PatientsMaster/PatientsListing/PatientsListing";
import PatientProfile from "./pages/PatientsMaster/PatientProfile/PatientProfile";
import AddPatients from "./pages/PatientsMaster/AddPatients/AddPatients";
import ApiMsgLog from "./pages/ApiMsgLog/ApiMsgLog";
import InventoryOrderList from "./pages/InventoryOrder/InventoryOrderList/InventoryOrderList";
import OrderDetail from "./pages/InventoryOrder/InventoryOrderDetail/OrderDetail";
import UsageTransactions from "./pages/UsageTransactions/UsageTransactions";
import UsageTransactionProfile from "./pages/UsageTransactions/UsageTransactionProfile/UsageTransactionProfile";
import AthenaTransactions from "./pages/AthenaTransactions/AthenaTransactions";
import AthenaTransactionProfile from "./pages/AthenaTransactions/AthenaTransactionProfile/AthenaTransactionProfile";
import AthenaTransactionAdd from "./pages/AthenaTransactions/AddAthenaTransaction/AthenaTransactionAdd";
import AthenaSettings from "./pages/AthenaHealthSettings/AthenaSetting/AthenaSettings";
import WarningItemListing from "./pages/ItemWarningMaster/WarningItemListing/WarningItemListing";
import WarningItemProfile from "./pages/ItemWarningMaster/WarningItemProfile/WarningItemProfile";
import AddWarningItem from "./pages/ItemWarningMaster/AddWarningItem/AddWarningItem";
import TRReport from "./pages/TrReport/TRReport";
import LabelListing from "./pages/LabelMaster/LabelListing/LabelListing";
import LabelDetails from "./pages/LabelMaster/LabelDetails/LabelDetails";
import AddLabel from "./pages/LabelMaster/AddLabel/AddLabel";
import OrderingProductReport from "./pages/Reports/OrderingProductReport/OrderingProductReport";
import OrderingProductInventoryReport from "./pages/Reports/OrderingProductInventoryReport/OrderingProductInventoryReport";
import RefrigeratorList from "./pages/RefrigeratorManagement/RefrigeratorListing/RefrigeratorList";
import AddRefrigerator from "./pages/RefrigeratorManagement/AddRefrigerator/AddRefrigerator";
import Faq from "./pages/FAQs/Faq";
import EmrEmulator from "./pages/emrEmulator/EmrEmulator";
import NotFound from "./global/404page/NotFound";
import RxHistoryReport from "./pages/Reports/RxHistoryReport/RxHistoryReport";
import CostHistoryReport from "./pages/Reports/CostHistoryReport/CostHistoryReport";
import ExpiringInventoryReport from "./pages/Reports/ExpiringInventoryReport/ExpiringInventoryReport";
import EmailExpiredInventoryReport from "./pages/Reports/EmailExpiredInventoryReport/EmailExpiredInventoryReport";

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const dispatchThunk = async () => {
      await dispatch(fetchConfigData()).unwrap();
      setLoading(false);
    };
    dispatchThunk();
  }, [dispatch]);
  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="/forgot-password" element={<PublicRoute />}>
            <Route path="/forgot-password" element={<ForgetPassword />} />
          </Route>
          <Route path="/reset-password" element={<PublicRoute />}>
            <Route path="" element={<ResetPassword />} />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/users" element={<UserManagement />} />
            <Route path="/create-user" element={<AddUser />} />
            <Route path="/view-user" element={<UserProfile />} />
            <Route path="/records" element={<Records />} />
            <Route
              path="/inventory-status-report"
              element={<InventoryStatusReport />}
            />
            <Route
              path="/inventory-expire-report"
              element={<InventoryExpireReport />}
            />
            <Route
              path="/inventory-receiving-report"
              element={<InventoryReceivingReport />}
            />
            <Route
              path="/usage-detail-report"
              element={<UsageDetailsReport />}
            />
            <Route
              path="/transfer-detail-report"
              element={<TransferDetailsReport />}
            />
            <Route
              path="/adjustment-detail-report"
              element={<AdjusmentDetailsReport />}
            />
            <Route path="/reconciliation-report" element={<ReconReport />} />
            <Route
              path="/recalled-inventory-report"
              element={<RecalledInventoryReport />}
            />
            <Route path="/providers-list" element={<ProvidersList />} />
            <Route path="/locations-list" element={<LocationsList />} />
            <Route path="/users-list" element={<UsersList />} />
            <Route path="/medications-list" element={<MedicationsList />} />
            <Route path="/rx-history" element={<RxHistoryReport />} />
            <Route path="/cost-history" element={<CostHistoryReport/>}/>
            <Route path="/lot-search-item" element={<LotSearchItem />} />
            <Route
              path="/lot-search-patients"
              element={<LotSearchPatients />}
            />
            <Route
              path="/ordering-product-report"
              element={<OrderingProductReport />}
            />
            <Route
              path="/ordering-product-inventory-report"
              element={<OrderingProductInventoryReport />}
            />
            <Route path="/inventory" element={<Itemlisting />} />
            <Route path="/location-master" element={<LocationListing />} />
            <Route path="/view-location" element={<LocationProfile />} />
            <Route path="/add-location" element={<AddLocation />} />
            <Route path="/edit-location" element={<AddLocation />} />
            <Route path="/item-type-master" element={<ItemTypeListing />} />
            <Route path="/edit-item-master" element={<AddItemType />} />
            <Route path="/view-item-master" element={<ItemTypeProfile />} />
            <Route path="/add-item-master" element={<AddItemType />} />
            <Route path="/unit-master" element={<UnitListing />} />
            <Route path="/view-unit" element={<UnitProfile />} />
            <Route path="/add-unit" element={<AddUnit />} />
            <Route path="/edit-unit" element={<AddUnit />} />
            <Route path="/stock-master" element={<StockListing />} />
            <Route path="/view-stock" element={<StockProfile />} />
            <Route path="/add-stock" element={<AddStock />} />
            <Route path="/product-master" element={<ProductMasterListing />} />
            <Route
              path="/view-product-master"
              element={<ProductMasterProfile />}
            />
            <Route path="/add-product-master" element={<AddProductMaster />} />
            <Route path="/edit-product-master" element={<AddProductMaster />} />
            <Route path="/vendor-master" element={<VendorListing />} />
            <Route path="/view-vendor" element={<VendorProfile />} />
            <Route path="/add-vendor" element={<AddVendor />} />
            <Route path="/edit-vendor" element={<AddVendor />} />
            <Route path="/patient-master" element={<PatientsListing />} />
            <Route path="/view-patient/:id" element={<PatientProfile />} />
            <Route path="/add-patient" element={<AddPatients />} />
            <Route path="/edit-patient/:id" element={<AddPatients />} />
            <Route path="/edit-stock" element={<AddStock />} />
            <Route
              path="/ordering-product-master"
              element={<OrderingProductListing />}
            />
            <Route
              path="/view-ordering-product"
              element={<OrderingProductProfile />}
            />
            <Route
              path="/add-ordering-product"
              element={<AddOrderingProduct />}
            />
            <Route
              path="/edit-ordering-product"
              element={<AddOrderingProduct />}
            />
            <Route path="/roles-permissions" element={<RoleListing />} />
            <Route path="/add-role" element={<AddRole />} />
            <Route path="/edit-role/:id" element={<AddRole />} />
            <Route path="/api-message-log" element={<ApiMsgLog />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/add-setting" element={<AddSetting />} />
            <Route path="/edit-setting/:id" element={<AddSetting />} />
            <Route path="/view-setting/:id" element={<SettingProfile />} />
            <Route path="/athena-settings" element={<AthenaSettings />} />
            <Route path="/usage-transactions" element={<UsageTransactions />} />
            <Route
              path="/item-warning-master"
              element={<WarningItemListing />}
            />
            <Route
              path="/item-warning-profile/:id"
              element={<WarningItemProfile />}
            />
            <Route path="/add-item-warning" element={<AddWarningItem />} />
            <Route path="/edit-item-warning/:id" element={<AddWarningItem />} />
            <Route path="/tr-report" element={<TRReport />} />
            <Route path="/label-setup" element={<LabelListing />} />
            <Route path="/label-details/:id" element={<LabelDetails />} />
            <Route path="/add-label" element={<AddLabel />} />
            <Route path="/edit-label/:id" element={<AddLabel />} />
            <Route
              path="/athena-transaction"
              element={<AthenaTransactions />}
            />
            <Route
              path="/view-transaction/:id"
              element={<AthenaTransactionProfile />}
            />
            <Route
              path="/edit-transaction/:id"
              element={<AthenaTransactionAdd />}
            />
            <Route
              path="/usage-transactions-profile/:id"
              element={<UsageTransactionProfile />}
            />

            <Route path="/inventory-order" element={<InventoryOrderList />} />
            <Route
              path="/inventory-order-detail/:id"
              element={<OrderDetail />}
            />

            <Route path="/user-guide" element={<UserGuide />} />
            <Route path="/user-profile" element={<LoginUserProfile />} />
            <Route path="/edit-profile-user" element={<AddUserProfile />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/tickets" element={<Tickets />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/faqs" element={<Faq />} />
            <Route path="/create-inventory" element={<AddItem />} />
            <Route path="/view-inventory" element={<InventoryProfile />} />
            <Route path="/predictions" element={<Predictions />} />
            <Route path="/refrigerators" element={<RefrigeratorList />} />
            <Route path="/add-refrigerator" element={<AddRefrigerator />} />
            <Route path="/edit-refrigerator" element={<AddRefrigerator />} />
            <Route path="/edit-user" element={<AddUser />} />
            <Route path="/edit-inventory" element={<AddItem />} />
            <Route path="/expiring-inventory-report" element={<ExpiringInventoryReport />} />
            <Route path="/email-expired-inventory-report" element={<EmailExpiredInventoryReport />} />
          </Route>
          <Route path="/emr-emulator" element={<EmrEmulator />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
