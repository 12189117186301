import React, { useEffect, useState } from 'react';
import "./EmailExpiredInventoryReport.scss";
import useReports from '../../../Hooks/useReports';
import Loading from '../../../components/LoadingPage/Loading';
import { manageLineBreakFromString } from '../../../utils/commonUtils';

interface EmailExpiriedInventoryReportRes {
    message: string;
    status: boolean;
}

const EmailExpiredInventoryReport: React.FC = () => {
    const [reportData, setReportData] = useState<EmailExpiriedInventoryReportRes | undefined>();
    const { emailExpiredInventoryReportAPI, emailExpiredInventoryReportLoading } = useReports();

    useEffect(() => {
        fetchEmailExpiredInventoryReport();
    }, []);

    const fetchEmailExpiredInventoryReport = async () => {
        const res = await emailExpiredInventoryReportAPI({});
        setReportData(res);
    }

    

    return (
        <div className='expiring-inventory-report-wrapper'>
            {emailExpiredInventoryReportLoading ? <Loading /> : null}
            <div
                className={`report-content ${reportData?.status ? 'success-txt' : 'error-txt'}`}
                dangerouslySetInnerHTML={{ __html: manageLineBreakFromString(reportData?.message ?? "") }}
            />
        </div>
    )
}

export default EmailExpiredInventoryReport;