import React,{ forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Select from "react-select";
import useLabelSetup from "../../Hooks/useLabelSetup";
import { LabelSetup } from "../../redux/labelSetup/labelSetupSlice";
import useInventory from "../../Hooks/useInventory";
import moment from "moment";
import { getBarcodeVales } from "../../utils/commonUtils";
interface labelOption {
    value: string;
    label: string;
}
interface IPrintBarcodeDropdown {
    selectedLabel: labelOption;
    setSelectedLabel: (value:labelOption)=>void;
    ref:any

}
const PrintBarcodeLabelDropdown = forwardRef(
  ({ setSelectedLabel, selectedLabel }: IPrintBarcodeDropdown, ref) => {
    const [searchText, setSearchText] = useState<string>("");
    const [printLabelId, setPrintLabelId] = useState<LabelSetup[]>([]);
    const { getLabelSetupAPI } = useLabelSetup();
    const { barcodePrintAPI } = useInventory();

    const getBarcodeLabels = () => {
      getLabelSetupAPI({ searchText }).then((res) => {
        const options = res?.map((option: any) => ({
          value: option.id,
          label: option.labelName,
        }));
        setPrintLabelId(options);

        // If options are returned, set the first one as the default selected label
        if (options?.length > 0) {
          setSelectedLabel(options[0]);
        }
      });
    };

    const printBarcode = (lableId:string,selectedItem:any) => {
      const barcodeVales = getBarcodeVales(selectedItem);
      barcodePrintAPI({
          "lableId": lableId,
          "barcodeVales": barcodeVales
      }).then((res: any) => {
        window.open(res, "_blank", "noopener,noreferrer");
      });
    };

    useImperativeHandle(ref, () => ({
      printBarcode
    }));

    useEffect(() => {
      getBarcodeLabels();
    }, []);

    return (
      <div className="barcodes-list-wrapper invt-overview-info-box">
        {/* <label>Select Label</label> */}
        <Select
          classNamePrefix="react-select"
          options={printLabelId}
          id="locationId"
          name="locationId"
          value={printLabelId?.find(
            (option: any) => selectedLabel?.value === option.value
          )}
          onChange={(option: any) => {
            setSelectedLabel(option);
          }}
          className="react-select-container"
        />
      </div>
    );
  }
);

export default PrintBarcodeLabelDropdown;