
import duplicateitemmodalimge from "../../assets/images/duplicate-item-modal-image.svg";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { toast } from "react-toastify";
import CustomModal from "../customModal/CustomModal";
import { useAppSelector } from "../../redux/hooks";
import useUser from "../../Hooks/useUser";
import { selectCurrentUserId } from "../../redux/auth/authSlice";
import Loading from "../LoadingPage/Loading";
import { useNavigate } from "react-router-dom";

interface  IDuplicateUser{
  selectedUser: {id:string,userName:string, email:string};
  showModal: Boolean; 
  toggleDuplicateModal: () => void;
  reloadListing?: Function;
  redirect?: string;
  userList: any;
}
const DublicateUserModel = ({
    selectedUser,
    showModal,
    toggleDuplicateModal,
    reloadListing,
    redirect,
    userList
}: IDuplicateUser) =>{
    const navigate = useNavigate();
    const loginUserId = useAppSelector(selectCurrentUserId);
    const {userDuplicate, duplicateUserLoading } = useUser(); 
    const { handleChange, values, handleSubmit, touched, errors, resetForm } = useFormik({
        initialValues: {newUserName: `${selectedUser?.userName}`, email: `${selectedUser?.email}`},
        validationSchema: Yup.object({
          newUserName: Yup.string()
            .max(20, "User Name can not be more than 20 characters")
            .test('userName-required', 'User Name is required', function (value) {
                const { email } = this.parent;
                // Check if email is empty and userName value is missing
                if (!email && !value) {
                return this.createError({ message: 'User Name or Email is Required' });
                }
                return true; // Otherwise, validation passes
            })
            .notRequired(),
          email: Yup.string()
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                "Please enter a valid Email"
            )
            .max(100, "Email cannot be more than 100 characters")
            .test('email-required', 'Email is required', function (value) {
                const { newUserName } = this.parent;
                // Check if userName is empty and email value is missing
                if (!newUserName && !value) {
                return this.createError({ message: 'Email or User Name is required' });
                }
                return true; // Otherwise, validation passes
            })
            .notRequired(), // If userName is filled, email is not required
        }),
        onSubmit: async (values: any, { setSubmitting, setErrors }: FormikHelpers<any>) => {
          try {
                const existName =  userList.some((item:any) => item?.userName === values?.newUserName);
                const existEmail = userList.some((item:any) =>item?.email === values?.email);
                if (existName || selectedUser?.userName === values?.newUserName || existEmail || selectedUser?.email === values?.email) {
                    const errors:any = {};
                    if (existName || userList?.userName === values?.newUserName) {
                        errors.newUserName = 'Username should not be the same as the existing username';
                    }
                    if (existEmail || userList?.email === values?.email) {
                        errors.email = 'Email should not be the same as the existing email';
                    }
                    // Set the errors if any
                    if (Object.keys(errors).length > 0) {
                        setErrors(errors);
                    }
                  return
                }else{
                await userDuplicate({ 
                    id: selectedUser.id,
                    createdBy: loginUserId ?? "",
                    email: values?.email ?? null,
                    userName: values?.newUserName ?? null

                  }).then((res: any) => {
                    if (res) {
                        resetForm();
                        toggleDuplicateModal();
                        if(redirect){
                          navigate(redirect);
                        }else{
                          reloadListing && reloadListing();
                        }

                    }
                    return res;
                    })
                    .catch((err) => {
                        toast.error(err?.message);
                        return err;
                });
              } 
            } catch (err) {}
          setSubmitting(false);
        },
        enableReinitialize: true,
      });
    return (
        <>
          {duplicateUserLoading ? <Loading /> : null}
          {showModal && (
            <CustomModal
              title=""
              content={
                <>
                  <div className="modal--content--wrapper">
                    <img src={duplicateitemmodalimge} alt="" />
                    <h5>
                      Are you sure you want to <br />
                      duplicate this User '{selectedUser?.userName}'?
                    </h5>
                    <div className="modal--inputbox">
                      <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-4">
                        <Form.Label>
                            User Name{values.email ? "" : <sup>*</sup>}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter User Name"
                            id="newUserName"
                            name="newUserName"
                            onChange={(e) => handleChange("newUserName")(e.target.value ?? "")}
                            value={values.newUserName}
                            isInvalid={touched.newUserName && !!errors.newUserName}
                            />
                            {touched.newUserName && errors.newUserName ? (
                            <Form.Control.Feedback type="invalid">
                                {errors.newUserName}
                            </Form.Control.Feedback>
                            ) : null}
                        </Form.Group>
                        <Form.Group className="mb-4">
                        <Form.Label>
                            Email{values.newUserName ? "" : <sup>*</sup>}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Email Address"
                            id="email"
                            name="email"
                            onChange={(e) => handleChange("email")(e.target.value)}
                            value={values.email}
                            isInvalid={touched.email && !!errors.email}
                        />
                            {touched.email && errors.email ? (
                        <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>
                            ) : null}
                        </Form.Group>
                        <Form.Group className="full-width text-center">
                          <Button
                            style={{ marginRight: "10px" }}
                            variant="outline-primary"
                            onClick={()=>{
                              resetForm();
                              toggleDuplicateModal();
                            }}
                          >
                            Cancel
                          </Button>
                          <Button type="submit" variant="primary">
                            Duplicate
                          </Button>
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                </>
              }
              onClose={toggleDuplicateModal}
            />
          )}
        </>
      );
};

export default DublicateUserModel;