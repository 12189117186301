import React, { useEffect, useState } from "react";
import "./faqs.scss";
import CommonHeader from "../../components/CommonHeader";
import FAQSLIST from "./Faqs.json";

const Faq = () => {
  const [searchVal, setSearchVal] = useState<string>("");
  const [faqData, setFaqData] = useState(FAQSLIST);

  // Function to highlight searched text
  const highlightText = (text: string) => {
    if (!searchVal) return text;
    const regex = new RegExp(`(${searchVal})`, "gi");
    return text.replace(regex, `<mark>$1</mark>`);
  };

  useEffect(() => {
    if (!searchVal) {
      setFaqData(FAQSLIST);
    } else {
      const filteredData = FAQSLIST.map((section) => {
        const filteredFaqs = section.faqs.filter(
          (faq) =>
            faq.question.toLowerCase().includes(searchVal.toLowerCase()) ||
            faq.ans.toLowerCase().includes(searchVal.toLowerCase())
        );
        return { ...section, faqs: filteredFaqs };
      }).filter((section) => section.faqs.length > 0);

      setFaqData(filteredData);
    }
  }, [searchVal]);

  return (
    <div className="faqs-wrapper">
      <CommonHeader
        pageTitle="FAQs"
        pageSubtitle="Search here to get answers to your questions"
        searchValue={searchVal}
        handleSearchChange={setSearchVal}
      />

      <div className="faq-list-wrapper">
        {faqData.length > 0 ? (
          faqData.map((item) => (
            <div className="faq-section" key={item.section_id}>
              <div className="faq-section-title">
                <span dangerouslySetInnerHTML={{ __html: item.section_icon }}></span>
                <h4>{item.section_name}</h4>
              </div>
              <div className="accordion">
                {item.faqs.map((faq) => (
                  <div className="accordion-item" key={faq.id}>
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#que-${item.section_id}-${faq.id}`}
                        aria-expanded="false"
                        aria-controls={`que-${item.section_id}-${faq.id}`}
                      >
                        <div dangerouslySetInnerHTML={{ __html: highlightText(faq.question) }}></div>
                      </button>
                    </h2>
                    <div
                      id={`que-${item.section_id}-${faq.id}`}
                      className="accordion-collapse collapse"
                    >
                      <div className="accordion-body">
                        <span dangerouslySetInnerHTML={{ __html: highlightText(faq.ans) }} />
                        {faq.image && (
                          <img src={faq.image} alt={faq.question} width={100} />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p className="no-results">No FAQs found for "{searchVal}"</p>
        )}
      </div>
    </div>
  );
};

export default Faq;
