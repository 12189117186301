import CustomModal from "../../../../components/customModal/CustomModal";
import { Button, Form, Table } from "react-bootstrap";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useUser from "../../../../Hooks/useUser";
import { useState } from "react";
import Loading from "../../../../components/LoadingPage/Loading";
import { useAppDispatch } from "../../../../redux/hooks";
import { setSelectedNpiData } from "../../../../redux/user/userSlice";
import "./../adduser.scss";
import Select from "react-select";
import { statesDDData } from "./../statesDropdownData";
import React from "react";
export const NpiPopupModel = ({ toggleNpiSearchModal, userData }: any) => {
  const dispatch = useAppDispatch();
  const { getNpiData, getNpiLoading } = useUser();
  const [selectRow, setSelectRow] = useState<any>();
  const initialValues = {
    firstName: userData.fName ?? "",
    lastName: userData.lName ?? "",
    state: "",
    npi: userData.deaNumber ?? "",
    country: "US",
    limit: 100,
  };
  const [npiData, setNpiData] = useState([]);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema: Yup.object()
      .shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        state: Yup.string(),
        npi: Yup.string().matches(/^\d{10}$/, "NPI must be a 10-digit number"),
        country: Yup.string(),
      })
      .test({
        test: function (value) {
          const { firstName, lastName, npi } = value || {};
          // Check if at least one field is present
          if (!firstName && !lastName && !npi) {
            throw new Yup.ValidationError(
              "At least 1 field is required",
              null,
              "npi",
            );
          }
          return true;
        },
      }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        const res = await getNpiData(values);
        setNpiData(res);
      } catch (err: any) {}
      setSubmitting(false);
    },
  });
  const selectRowData = (data: any) => {
    setSelectRow(data);
  };
  const selectNpiData = () => {
    dispatch(setSelectedNpiData(selectRow));
    toggleNpiSearchModal();
  };

  return (
    <>
      <CustomModal
        id="npi-search-modal"
        title="NPI Search"
        content={
          <>
            {getNpiLoading ? <Loading /> : null}
            <Form onSubmit={handleSubmit}>
              <div className="npi-search-wrapper">
                <div className="npi-search-box">
                  <div className="npi-search-input-box">
                    <Form.Group>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        id="firstName"
                        name="firstName"
                        onChange={handleChange}
                        value={values.firstName}
                      />
                    </Form.Group>
                  </div>
                  <div className="npi-search-input-box">
                    <Form.Group>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        id="lastName"
                        name="lastName"
                        onChange={handleChange}
                        value={values.lastName}
                      />
                    </Form.Group>
                  </div>
                  <div className="npi-search-input-box">
                    <Form.Group>
                      <Form.Label>State</Form.Label>
                      <Select
                        classNamePrefix="react-select"
                        options={statesDDData}
                        id="state"
                        name="state"
                        value={statesDDData?.find(
                          (option: any) => option.value === values.state,
                        )}
                        onChange={(option: any) =>
                          handleChange("state")(option.value)
                        }
                        className={`${
                          touched.state && errors.state
                            ? "is-invalid-border"
                            : ""
                        } react-select-container `}
                      />
                      {touched.state && errors.state && (
                        <Form.Control.Feedback type="invalid">
                          {errors.state}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </div>
                  <div className="npi-search-input-box">
                    <Form.Group>
                      <Form.Label>NPI</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="NPI"
                        id="npi"
                        name="npi"
                        onChange={handleChange}
                        value={values.npi}
                        isInvalid={touched.npi && !!errors.npi}
                      />
                      {touched.npi && errors.npi && (
                        <Form.Control.Feedback type="invalid">
                          {`${errors.npi}`}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </div>
                  <div className="npi-search-input-box">
                    <label className="form-label">&nbsp;</label>
                    <button
                      type="submit"
                      className="npi-search-btn"
                      onClick={handleSubmit as any}
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div className="npi-search-result-table-wrapper">
                  <Table bordered={false}>
                    <thead>
                      <tr>
                        <th>title</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>NPI</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Postal Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      {npiData?.map((x: any) => (
                        <tr
                          key={x.deaNumber}
                          onClick={() => {
                            selectRowData(x);
                          }}
                          className={
                            selectRow?.deaNumber === x.deaNumber ? "active" : ""
                          }
                        >
                          <td>{x.title || "-"}</td>
                          <td>{x.firstName}</td>
                          <td>{x.lastName}</td>
                          <td>{x.deaNumber}</td>
                          <td>{x.city}</td>
                          <td>{x.state}</td>
                          <td>{x.postalCode}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Form>
          </>
        }
        footer={
          <div className="footer-content">
            <div className="footer-action-button-box">
              <Button
                variant="outline-primary"
                onClick={toggleNpiSearchModal}
                style={{ marginRight: "16px" }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={!selectRow}
                onClick={selectNpiData}
              >
                Use Data
              </Button>
            </div>
          </div>
        }
        onClose={toggleNpiSearchModal}
      />
    </>
  );
};
